import { createReducer, on } from '@ngrx/store';
import { EmissionEquivalents, EmissionFigures, emptyEquivalents } from '../../model/emissions.model';
import { UTILITY_ENVIRONMENTAL_IMPACT_FACTORS } from '../../../constants/utility.constant';
import {
  loadEmissionsForCity,
  loadEmissionsForCityError,
  loadEmissionsForCitySuccess,
  loadEmissionsForFacility,
  loadEmissionsForFacilityError,
  loadEmissionsForFacilitySuccess,
  loadEmissionsForGroup,
  loadEmissionsForGroupError,
  loadEmissionsForGroupSuccess,
} from '../actions/emissions.action';

export default interface EmissionsState {
  emissionFigures?: EmissionFigures;
  equivalents: EmissionEquivalents;
  isEmissionsLoading: boolean;
}

export const initialState: EmissionsState = {
  emissionFigures: undefined,
  equivalents: emptyEquivalents,
  isEmissionsLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    loadEmissionsForCity,
    loadEmissionsForGroup,
    loadEmissionsForFacility,
    (state): EmissionsState => ({
      ...state,
      isEmissionsLoading: true,
    }),
  ),
  on(
    loadEmissionsForCitySuccess,
    loadEmissionsForGroupSuccess,
    loadEmissionsForFacilitySuccess,
    (state, { emissionsData }): EmissionsState => ({
      ...state,
      emissionFigures: {
        ...emissionsData,
        emissionChange: ((emissionsData.totalEmissions - emissionsData.previousYearTotalEmissions) / emissionsData.previousYearTotalEmissions) * 100,
        consumptionChange:
          ((emissionsData.energyConsumption - emissionsData.previousYearEnergyConsumption) / emissionsData.previousYearEnergyConsumption) * 100,
        costChange: ((emissionsData.totalEnergyCost - emissionsData.previousYearTotalEnergyCost) / emissionsData.previousYearTotalEnergyCost) * 100,
      },
      equivalents: {
        miles: emissionsData.totalEmissions / UTILITY_ENVIRONMENTAL_IMPACT_FACTORS.metricTonsCo2ePerMile,
        trees: emissionsData.totalEmissions / UTILITY_ENVIRONMENTAL_IMPACT_FACTORS.metricTonsCo2ePerTree,
        gasoline: emissionsData.totalEmissions / UTILITY_ENVIRONMENTAL_IMPACT_FACTORS.metricTonsCo2ePerGallonGasoline,
        homeElectricity: emissionsData.totalEmissions / UTILITY_ENVIRONMENTAL_IMPACT_FACTORS.metricTonsCo2ePerHomeElectricityYearly,
      },
      isEmissionsLoading: false,
    }),
  ),
  on(
    loadEmissionsForCityError,
    loadEmissionsForGroupError,
    loadEmissionsForFacilityError,
    (state): EmissionsState => ({
      ...state,
      isEmissionsLoading: false,
    }),
  ),
);
