import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AlsoEnergyHardware } from 'brain-data/model/alsoEnergy.model';
import { AirQualityData } from 'brain-data/model/airQuality.model';
import { LiveWeather } from 'brain-data/model/liveWeather.model';

export const ALSO_ENERGY_URL = `${environment.apiBaseUrl}/alsoEnergy`;
export const AIR_QUALITY_GOOGLE_URL = `${environment.apiAirQualityUrl}/currentConditions:lookup`;
export const LIVE_WEATHER_URL = `${environment.weatherApiUrl}/current.json?key=${environment.weatherApiKey}`;

@Injectable({
  providedIn: 'root',
})
export class ExternalProviderService {
  constructor(private http: HttpClient) {}

  getHardwareForFacility(facilityId: number): Observable<AlsoEnergyHardware[]> {
    const url = `${ALSO_ENERGY_URL}/facilities/${facilityId}/hardware`;
    return this.http.get<AlsoEnergyHardware[]>(url);
  }



  getAirQuality(latitude: number, longitude: number): Observable<AirQualityData> {
    const requestBody = {
      location: {
        latitude,
        longitude,
      },
    };

    return this.http.post<AirQualityData>(`${AIR_QUALITY_GOOGLE_URL}?key=${environment.googleMapsApiKey}`, requestBody);
  }

  getLiveWeather(city: string): Observable<LiveWeather> {
    return this.http.get<LiveWeather>(`${LIVE_WEATHER_URL}&q=${city}`).pipe(
      catchError(error => {
        console.error('Error fetching live weather:', error);
        return of({
          current: {
            temp_f: 'unavailable',
            humidity: 'unavailable',
            wind_mph: 'unavailable',
            uv: 'unavailable',
            condition: { icon: '' },
          },
        } as LiveWeather);
      })
    );
  }
}
