import { createReducer, on } from '@ngrx/store';

import { User, UserDetails } from 'brain-data/model/users.model';
import {
  archiveUser,
  archiveUserError,
  archiveUserSuccess,
  assignUserGroupPermissions,
  assignUserGroupPermissionsError,
  assignUserGroupPermissionsSuccess,
  createUser,
  createUserError,
  createUserSuccess,
  editUser,
  editUserError,
  editUserSuccess,
  loadUser,
  loadUserDetails,
  loadUserDetailsError,
  loadUserDetailsSuccess,
  loadUserError,
  loadUsers,
  loadUsersError,
  loadUsersSuccess,
  loadUserSuccess,
  resetLoadingUser,
  resetUser,
} from '../actions/user.action';

export default interface UserState {
  user?: User;
  users: User[];
  usersLoading?: boolean;
  userLoading?: boolean;
  userError?: Error;
  usersError?: Error;
  userDetails?: UserDetails;
  userDetailsLoading?: boolean;
  userGroupPermissionsLoading?: boolean;
  usersDetailsError?: Error;
}

export const initialState: UserState = {
  user: undefined,
  users: [],
  usersLoading: undefined,
  userLoading: undefined,
  userError: undefined,
  usersError: undefined,
  userDetails: undefined,
  userDetailsLoading: undefined,
  userGroupPermissionsLoading: undefined,
  usersDetailsError: undefined,
};

export const reducer = createReducer(
  initialState,
  on(resetUser, (state): UserState => ({ ...state, userLoading: undefined, user: undefined, userError: undefined })),
  on(resetLoadingUser, (state): UserState => ({ ...state, userGroupPermissionsLoading: undefined })),

  on(loadUsers, (state): UserState => ({ ...state, usersLoading: true, usersError: undefined })),
  on(loadUsersSuccess, (state, { users }): UserState => ({ ...state, users, usersLoading: false })),
  on(loadUsersError, (state, { error }): UserState => ({ ...state, usersLoading: false, usersError: error })),

  on(loadUser, (state): UserState => ({ ...state, userLoading: true, userError: undefined })),
  on(loadUserSuccess, (state, { user }): UserState => ({ ...state, user, userLoading: false })),
  on(loadUserError, (state, { error }): UserState => ({ ...state, userLoading: false, userError: error })),

  on(createUser, (state): UserState => ({ ...state, userLoading: true, userError: undefined })),
  on(createUserSuccess, (state, { user }): UserState => ({ ...state, users: [...state.users, user], user, userLoading: false })),
  on(createUserError, (state, { error }): UserState => ({ ...state, userLoading: false, userError: error })),

  on(editUser, archiveUser, (state): UserState => ({ ...state, userLoading: true, userError: undefined })),
  on(
    editUserSuccess,
    (state, { user }): UserState => ({
      ...state,
      users: state.users.map(u => (u.userId === user.userId ? user : u)),
      user,
      userLoading: false,
    }),
  ),
  on(
    archiveUserSuccess,
    (state): UserState => ({
      ...state,
      userLoading: false,
    }),
  ),
  on(editUserError, archiveUserError, (state, { error }): UserState => ({ ...state, userLoading: false, userError: error })),

  on(loadUserDetails, (state): UserState => ({ ...state, userDetailsLoading: true, usersDetailsError: undefined })),
  on(loadUserDetailsSuccess, (state, { userDetails }): UserState => ({ ...state, userDetails, userDetailsLoading: false })),
  on(loadUserDetailsError, (state, { error }): UserState => ({ ...state, userDetailsLoading: false, usersDetailsError: error })),

  on(assignUserGroupPermissions, (state): UserState => ({ ...state, userGroupPermissionsLoading: true, usersDetailsError: undefined })),
  on(assignUserGroupPermissionsSuccess, (state): UserState => ({ ...state, userGroupPermissionsLoading: false })),
  on(assignUserGroupPermissionsError, (state, { error }): UserState => ({ ...state, userGroupPermissionsLoading: false, usersDetailsError: error })),
);
