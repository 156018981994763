import { createReducer, on } from '@ngrx/store';

import {
  resetAlertType,
  notifyAlert,
  notifyAlertError,
  notifyAlertSuccess,
  updateAlertType,
  snoozeAlert,
  snoozeAlertSuccess,
  snoozeAlertError,
  notifyAndSnoozeAlert,
  notifyAndSnoozeAlertSuccess,
  notifyAndSnoozeAlertError,
  resetAlertLoading,
} from '../actions/alert.action';

export default interface AlertState {
  alertType?: string;
  alertLoading?: boolean;
  alertError?: Error;
}

export const initialState: AlertState = {
  alertType: undefined,
  alertLoading: undefined,
  alertError: undefined,
};

export const reducer = createReducer(
  initialState,
  on(resetAlertType, (state): AlertState => ({ ...state, alertType: undefined })),
  on(resetAlertLoading, (state): AlertState => ({ ...state, alertLoading: undefined })),
  on(updateAlertType, (state, { alertType }): AlertState => ({ ...state, alertType })),

  on(notifyAlert, snoozeAlert, notifyAndSnoozeAlert, (state): AlertState => ({ ...state, alertLoading: true })),
  on(notifyAlertSuccess, snoozeAlertSuccess, notifyAndSnoozeAlertSuccess, (state): AlertState => ({ ...state, alertLoading: false })),
  on(notifyAlertError, snoozeAlertError, notifyAndSnoozeAlertError, (state, { error }): AlertState => ({ ...state, alertLoading: false, alertError: error })),
);
