import { FacilitySummary } from './facility.model';

export interface Alert {
  id: number;
  provider?: string;
  description?: string;
  time?: string;
  type?: AlertType;
  facility?: FacilitySummary;
  status: AlertStatus;
  lastUpdate?: string;
  snoozedUntil?: string;
  body?: AlertBody;
}

export interface AlertBody {
  /* eslint-disable @typescript-eslint/naming-convention */
  Latitude?: number;
  /* eslint-disable @typescript-eslint/naming-convention */
  Longitude?: number;
  /* eslint-disable @typescript-eslint/naming-convention */
  Account_Number?: string;
}

export interface AlertType {
  type?: string;
  subType?: string;
}

export enum AlertStatus {
  closed = 'CLOSED',
  open = 'OPEN',
  snoozed = 'SNOOZED',
}

export interface AlertNotification {
  emails: string[];
  note: string;
}
