import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import {
  loadTotalUtilityContracts,
  loadTotalUtilityContractsError,
  loadTotalUtilityContractsSuccess,
  loadUtilityContractsWithFacilityUnassigned,
  loadUtilityContractsWithFacilityUnassignedError,
  loadUtilityContractsWithFacilityUnassignedSuccess,
  updateAndAssignFacilityToUtilityContract,
  updateAndAssignFacilityToUtilityContractSuccess,
  updateAndUnAssignFacilityToUtilityContracts,
  updateAndUnAssignFacilityToUtilityContractsError,
  updateAndUnAssignFacilityToUtilityContractsSuccess,
  updateUtilityContracts,
  updateUtilityContractsError,
  updateUtilityContractsSuccess,
} from '../actions/utility-contract.action';
import { UtilityContractService } from 'brain-data/service/utility/utility-contract.service';
import { loadFacilitiesAtStartup } from '../actions/facility.actions';

@Injectable()
export class UtilityContractsEffects {
  loadTotalUtilityContractsWithFacilityUnassignedEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilityContractsWithFacilityUnassigned),
      switchMap(() =>
        this.utilityContractService.getUtilityContractsWithFacilityUnassigned().pipe(
          map(utilityContracts => loadUtilityContractsWithFacilityUnassignedSuccess({ utilityContracts })),
          catchError(error => of(loadUtilityContractsWithFacilityUnassignedError({ error }))),
        ),
      ),
    );
  });

  loadTotalUtilityContractsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadTotalUtilityContracts),
      switchMap(() =>
        this.utilityContractService.getTotalUtilityContracts().pipe(
          map(totalUtilityContracts => loadTotalUtilityContractsSuccess({ totalUtilityContracts })),
          catchError(error => of(loadTotalUtilityContractsError({ error }))),
        ),
      ),
    );
  });

  updateAndAssignFacilityToUtilityContractEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAndAssignFacilityToUtilityContract),
      switchMap(({ utilityContractNumber, facilityId }) =>
        this.utilityContractService.updateAndAssignFacilityToUtilityContract(utilityContractNumber, facilityId).pipe(
          map(() => updateAndAssignFacilityToUtilityContractSuccess()),
          catchError(error => of(loadTotalUtilityContractsError({ error }))),
        ),
      ),
    );
  });

  updateAndUnAssignFacilityToUtilityContractsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAndUnAssignFacilityToUtilityContracts),
      switchMap(({ utilityContracts }) =>
        this.utilityContractService.updateAndUnAssignFacilityToUtilityContracts(utilityContracts).pipe(
          map(() => updateAndUnAssignFacilityToUtilityContractsSuccess()),
          catchError(error => of(updateAndUnAssignFacilityToUtilityContractsError({ error }))),
        ),
      ),
    );
  });

  updateUtilityContractsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateUtilityContracts),
      switchMap(({ facilityId, updateUtilityContractRequest }) =>
        this.utilityContractService.updateUtilityContracts(facilityId, updateUtilityContractRequest).pipe(
          map(() => updateUtilityContractsSuccess()),
          catchError(error => of(updateUtilityContractsError({ error }))),
        ),
      ),
    );
  });

  triggerLoadFacilitiesAfterUpdateFacilityUtilityContractEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(updateAndAssignFacilityToUtilityContractSuccess),
      map(() => loadFacilitiesAtStartup()),
    );
  });

  constructor(
    private actions$: Actions,
    private utilityContractService: UtilityContractService,
  ) {}
}
