import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import {
  loadReportDetailsAndEmissionsForCity,
  loadReportDetailsForCityError,
  loadReportDetailsForCitySuccess,
  loadReportDetailsAndEmissionsForFacility,
  loadReportDetailsForFacilityError,
  loadReportDetailsForFacilitySuccess,
  loadReportDetailsAndEmissionsForGroup,
  loadReportDetailsForGroupError,
  loadReportDetailsForGroupSuccess,
  loadReportPerformanceForGroupError,
  loadReportPerformanceForGroupSuccess,
  loadSummaryReportForCity,
  loadSummaryReportForCityError,
  loadSummaryReportForCitySuccess,
  loadSummaryReportForGroup,
  loadSummaryReportForGroupError,
  loadSummaryReportForGroupSuccess,
} from '../actions/report.action';
import { ReportService } from 'brain-data/service/report/report.service';

@Injectable()
export class ReportEffects {
  loadSummaryReportForCityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSummaryReportForCity),
      switchMap(({ from, to }) =>
        this.reportService.getSummaryReportForCity(from, to).pipe(
          map(summaryReport => loadSummaryReportForCitySuccess({ summaryReport })),
          catchError(error => of(loadSummaryReportForCityError({ error }))),
        ),
      ),
    );
  });

  loadSummaryReportForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSummaryReportForGroup),
      switchMap(({ facilityGroupId, from, to }) =>
        this.reportService.getSummaryReportForGroup(facilityGroupId, from, to).pipe(
          map(summaryReport => loadSummaryReportForGroupSuccess({ summaryReport })),
          catchError(error => of(loadSummaryReportForGroupError({ error }))),
        ),
      ),
    );
  });

  loadReportDetailsForCityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadReportDetailsAndEmissionsForCity),
      switchMap(({ from, to }) =>
        this.reportService.getReportDetailsForCity(from, to).pipe(
          map(reportDetails => loadReportDetailsForCitySuccess({ reportDetails })),
          catchError(error => of(loadReportDetailsForCityError({ error }))),
        ),
      ),
    );
  });

  loadReportDetailsForFacilityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadReportDetailsAndEmissionsForFacility),
      switchMap(({ facilityId, from, to }) =>
        this.reportService.getReportDetailsForFacility(facilityId, from, to).pipe(
          map(reportDetails => loadReportDetailsForFacilitySuccess({ reportDetails })),
          catchError(error => of(loadReportDetailsForFacilityError({ error }))),
        ),
      ),
    );
  });

  loadReportDetailsForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadReportDetailsAndEmissionsForGroup),
      switchMap(({ facilityGroupId, from, to }) =>
        this.reportService.getReportDetailsForGroup(facilityGroupId, from, to).pipe(
          map(reportDetails => loadReportDetailsForGroupSuccess({ reportDetails })),
          catchError(error => of(loadReportDetailsForGroupError({ error }))),
        ),
      ),
    );
  });

  loadReportPerformanceForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadSummaryReportForGroup, loadReportDetailsAndEmissionsForGroup),
      switchMap(({ facilityGroupId, from, to }) =>
        this.reportService.getReportPerformanceForGroup(facilityGroupId, from, to).pipe(
          map(reportPerformance => loadReportPerformanceForGroupSuccess({ reportPerformance })),
          catchError(error => of(loadReportPerformanceForGroupError({ error }))),
        ),
      ),
    );
  });

  /* loadReportPerformanceDetailsForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadReportDetailsForFacility),
      switchMap(({ facilityId, from, to }) =>
        this.reportService.getReportPerformanceForFacility(facilityId, from, to).pipe(
          map(reportPerformance => loadReportPerformanceForGroupSuccess({ reportPerformance })),
          catchError(error => of(loadReportPerformanceForGroupError({ error }))),
        ),
      ),
    );
  }); */

  constructor(
    private actions$: Actions,
    private reportService: ReportService,
  ) {}
}
