import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root',
})
export class AuthDashboardGuard {
  constructor(private oauthService: OAuthService) {}

  canActivate() {
    if (!this.oauthService.hasValidAccessToken()) {
      this.handleInvalidToken();
      return false;
    }
    return true;
  }

  private handleInvalidToken(): void {
    this.oauthService.logOut();
  }
}
