import { createAction, props } from '@ngrx/store';
import { Role } from 'brain-data/model/role.model';

export const resetRoles = createAction('[Roles] reset roles');
export const resetRoleLoading = createAction('[Role] reset role loading');

export const loadRoles = createAction('[Roles] load roles');
export const loadRolesSuccess = createAction('[Roles] load roles success', props<{ roles: Role[] }>());
export const loadRolesError = createAction('[Roles] load roles error', props<{ error: Error }>());

export const loadUserRoles = createAction('[Roles] load user roles', props<{ userId: string }>());
export const loadUserRolesSuccess = createAction('[Roles] load user roles success', props<{ roles: Role[] }>());
export const loadUserRolesError = createAction('[Roles] load user roles error', props<{ error: Error }>());

export const assignUserRole = createAction('[Assign] insert user roles', props<{ userId: string; roleIds: string[]; roleNames: string[] }>());
export const assignUserRoleSuccess = createAction('[Assign] insert user roles success');
export const assignUserRoleError = createAction('[Assign] insert user roles error', props<{ error: Error }>());
