import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';
import { loadHardwareForFacilityError, loadHardwareForFacilitySuccess, } from '../actions/external-provider.action';
import { notifyAlsoEnergyDown, notifyAlsoEnergyUp } from '../actions/sub-system.action';
import {
  loadFacilityPerformanceSolarByFacilityGroupIdError,
  loadFacilityPerformanceSolarByFacilityGroupIdSuccess,
  loadFacilityPerformanceSolarByFacilityIdError,
  loadFacilityPerformanceSolarByFacilityIdSuccess
} from '../actions/facility.actions';

@Injectable()
export class SubSystemEffects {
  alsoEnergyDownEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        loadHardwareForFacilityError,
        loadFacilityPerformanceSolarByFacilityIdError,
        loadFacilityPerformanceSolarByFacilityGroupIdError,
      ),
      map(() => notifyAlsoEnergyDown())
    );
  });

  alsoEnergyUpEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        loadHardwareForFacilitySuccess,
        loadFacilityPerformanceSolarByFacilityIdSuccess,
        loadFacilityPerformanceSolarByFacilityGroupIdSuccess,
      ),
      map(() => notifyAlsoEnergyUp())
    );
  });

  constructor(private actions$: Actions) {
  }
}
