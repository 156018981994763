import { createReducer, on } from '@ngrx/store';
import { AlsoEnergyHardware } from 'brain-data/model/alsoEnergy.model';
import {
  loadHardwareForFacility,
  loadHardwareForFacilityError,
  loadHardwareForFacilitySuccess,
  loadAirQualityError,
  loadAirQualitySuccess,
  resetLoading,
  loadLiveWeatherSuccess,
  loadLiveWeatherError,
  loadDashboardAirData,
} from '../actions/external-provider.action';
import { AirQualityData } from 'brain-data/model/airQuality.model';
import { LiveWeather } from 'brain-data/model/liveWeather.model';

export default interface LiveFeedState {
  siteId: number;
  hardwareLoading?: boolean;
  airQualityLoading?: boolean;
  liveWeatherLoading?: boolean;
  hardwareList: AlsoEnergyHardware[];
  airQualityData?: AirQualityData;
  liveWeather?: LiveWeather;
}

export const initialState: LiveFeedState = {
  siteId: 0,
  hardwareLoading: undefined,
  airQualityLoading: undefined,
  hardwareList: [],
  airQualityData: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    resetLoading,
    (state): LiveFeedState => ({
      ...state,
      hardwareLoading: undefined,
      airQualityLoading: undefined,
    })
  ),
  on(
    loadHardwareForFacility,
    (state): LiveFeedState => ({
      ...state,
      hardwareList: [],
      hardwareLoading: true,
    })
  ),
  on(
    loadHardwareForFacilitySuccess,
    (state, { hardwareList }): LiveFeedState => ({
      ...state,
      hardwareList,
      hardwareLoading: false,
    })
  ),
  on(
    loadHardwareForFacilityError,
    (state): LiveFeedState => ({
      ...state,
      hardwareLoading: false,
    })
  ),
  on(
    loadDashboardAirData,
    (state): LiveFeedState => ({
      ...state,
      airQualityLoading: true,
      liveWeatherLoading: true,
    })
  ),
  on(
    loadAirQualitySuccess,
    (state, { airQualityData }): LiveFeedState => ({
      ...state,
      airQualityData,
      airQualityLoading: false,
    })
  ),
  on(
    loadAirQualityError,
    (state): LiveFeedState => ({
      ...state,
      airQualityLoading: false,
    })
  ),
  on(
    loadLiveWeatherSuccess,
    (state, { liveWeather }): LiveFeedState => ({
      ...state,
      liveWeather,
      liveWeatherLoading: false,
    })
  ),
  on(
    loadLiveWeatherError,
    (state): LiveFeedState => ({
      ...state,
      liveWeatherLoading: false,
    })
  )
);
