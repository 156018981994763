import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { UtilityService } from 'brain-data/service/utility/utility.service';
import { FacilityGroupService } from 'brain-data/service/facility-group/facility-group.service';
import {
  loadUtilitySummaries,
  loadUtilitySummariesSuccess,
  loadUtilitySummariesError,
  loadUtilitySummariesByFacilityId,
  loadUtilitySummariesByFacilityIdSuccess,
  loadUtilitySummariesByFacilityIdError,
  loadUtilitySummariesByFacilityGroupId,
  loadUtilitySummariesByFacilityGroupIdSuccess,
  loadUtilitySummariesByFacilityGroupIdError,
  loadUtilitiesDetailByFacilityIdAndDateRange,
  loadUtilitiesDetailByFacilityIdAndDateRangeSuccess,
  loadUtilitiesDetailByFacilityIdAndDateRangeError,
  loadUtilitiesDetailYearlyByFacilityIdAndDateRange,
  loadUtilitiesDetailYearlyByFacilityIdAndDateRangeSuccess,
  loadUtilitiesDetailYearlyByFacilityIdAndDateRangeError,
  loadUtilitiesDetailByFacilityGroupIdAndDateRange,
  loadUtilitiesDetailByFacilityGroupIdAndDateRangeSuccess,
  loadUtilitiesDetailByFacilityGroupIdAndDateRangeError,
  loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRange,
  loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeSuccess,
  loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeError,
  loadUtilitiesDetailByDateRange,
  loadUtilitiesDetailByDateRangeSuccess,
  loadUtilitiesDetailByDateRangeError,
  loadUtilitiesDetailYearlyByDateRange,
  loadUtilitiesDetailYearlyByDateRangeSuccess,
  loadUtilitiesDetailYearlyByDateRangeError,
  loadUtilitySaving,
  loadUtilitySavingSuccess,
  loadUtilitySavingError,
} from '../actions/utility.actions';
import { PublicService } from 'brain-data/service/utility/public.service';

@Injectable()
export class UtilityEffects {
  loadUtilitiesSummaryEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitySummaries),
      switchMap(({ startDate, endDate }) =>
        this.utilityService.getUtilitiesSummary(startDate, endDate).pipe(
          map(utilitySummaries => loadUtilitySummariesSuccess({ utilitySummaries })),
          catchError(error => of(loadUtilitySummariesError({ error })))
        )
      )
    );
  });

  loadUtilitiesSummaryByFacilityIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitySummariesByFacilityId),
      switchMap(({ facilityId, startDate, endDate }) =>
        this.utilityService.getUtilitiesSummaryByFacilityId(facilityId, startDate, endDate).pipe(
          map(utilitySummaries => loadUtilitySummariesByFacilityIdSuccess({ utilitySummaries })),
          catchError(error => of(loadUtilitySummariesByFacilityIdError({ error })))
        )
      )
    );
  });

  loadUtilitiesSummaryByFacilityGroupIdEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitySummariesByFacilityGroupId),
      switchMap(({ facilityGroupId, startDate, endDate }) =>
        this.facilityGroupService.getUtilitiesSummaryFacilityGroupId(facilityGroupId, startDate, endDate).pipe(
          map(utilitySummaries => loadUtilitySummariesByFacilityGroupIdSuccess({ utilitySummaries })),
          catchError(error => of(loadUtilitySummariesByFacilityGroupIdError({ error })))
        )
      )
    );
  });

  loadUtilitiesDetailByFacilityIdAndDateRangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitiesDetailByFacilityIdAndDateRange),
      switchMap(({ facilityId, startDate, endDate }) =>
        this.utilityService.getUtilitiesDetailsByFacilityIdAndDateRange(facilityId, startDate, endDate).pipe(
          map(utilitiesDetail => loadUtilitiesDetailByFacilityIdAndDateRangeSuccess({ utilitiesDetail })),
          catchError(error => of(loadUtilitiesDetailByFacilityIdAndDateRangeError({ error })))
        )
      )
    );
  });

  loadUtilitiesDetailYearlyByFacilityIdAndDateRangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitiesDetailYearlyByFacilityIdAndDateRange),
      switchMap(({ facilityId, startDate, endDate }) =>
        this.utilityService.getUtilitiesYearlyByFacilityIdAndDateRange(facilityId, startDate, endDate).pipe(
          map(utilitiesDetail => loadUtilitiesDetailYearlyByFacilityIdAndDateRangeSuccess({ utilitiesDetail })),
          catchError(error => of(loadUtilitiesDetailYearlyByFacilityIdAndDateRangeError({ error })))
        )
      )
    );
  });

  loadUtilitiesDetailByFacilityGroupIdAndDateRangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitiesDetailByFacilityGroupIdAndDateRange),
      switchMap(({ facilityGroupId, startDate, endDate }) =>
        this.facilityGroupService.getUtilitiesDetailsByFacilityGroupIdAndDateRange(facilityGroupId, startDate, endDate).pipe(
          map(utilitiesDetail => loadUtilitiesDetailByFacilityGroupIdAndDateRangeSuccess({ utilitiesDetail })),
          catchError(error => of(loadUtilitiesDetailByFacilityGroupIdAndDateRangeError({ error })))
        )
      )
    );
  });

  loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRange),
      switchMap(({ facilityGroupId, startDate, endDate }) =>
        this.facilityGroupService.gettUtilitiesYearlyByFacilityGroupIdAndDateRange(facilityGroupId, startDate, endDate).pipe(
          map(utilitiesDetail => loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeSuccess({ utilitiesDetail })),
          catchError(error => of(loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeError({ error })))
        )
      )
    );
  });

  loadUtilitiesDetailByDateRangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitiesDetailByDateRange),
      switchMap(({ startDate, endDate }) =>
        this.utilityService.getUtilitiesDetailsByDateRange(startDate, endDate).pipe(
          map(utilitiesDetail => loadUtilitiesDetailByDateRangeSuccess({ utilitiesDetail })),
          catchError(error => of(loadUtilitiesDetailByDateRangeError({ error })))
        )
      )
    );
  });

  loadUtilitiesDetailYearlyByDateRangeEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitiesDetailYearlyByDateRange),
      switchMap(({ startDate, endDate }) =>
        this.utilityService.getUtilitiesYearlyByDateRange(startDate, endDate).pipe(
          map(utilitiesDetail => loadUtilitiesDetailYearlyByDateRangeSuccess({ utilitiesDetail })),
          catchError(error => of(loadUtilitiesDetailYearlyByDateRangeError({ error })))
        )
      )
    );
  });

  loadUtilitiesSavingEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUtilitySaving),
      switchMap(({ startYear, endYear }) =>
        this.publicService.getAllUtilityValuesSaving(startYear, endYear).pipe(
          map(utilitiesSaving => loadUtilitySavingSuccess({ utilitiesSaving })),
          catchError(error => of(loadUtilitySavingError({ error })))
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private facilityGroupService: FacilityGroupService,
    private utilityService: UtilityService,
    private publicService: PublicService
  ) {}
}
