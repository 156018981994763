import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FacilityPerformanceYearly, FacilitySolarPerformance } from 'brain-data/model/facility-performance.model';
import { FACILITIES_URL } from '../facility/facility.service';
import { FACILITY_GROUPS_URL } from '../facility-group/facility-group.service';

@Injectable({
  providedIn: 'root',
})
export class FacilityPerformanceService {
  constructor(private http: HttpClient) {
  }

  getFacilityPerformancesBuildingByFacilityId(facilityId: number): Observable<FacilityPerformanceYearly> {
    return this.http.get<FacilityPerformanceYearly>(`${FACILITIES_URL}/${facilityId}/performance/building`);
  }

  getFacilityPerformancesBuildingByFacilityGroupId(facilityGroupId: number): Observable<FacilityPerformanceYearly[]> {
    return this.http.get<FacilityPerformanceYearly[]>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/performance/building`);
  }

  getFacilityPerformancesSolarByFacilityId(facilityId: number): Observable<FacilitySolarPerformance[]> {
    return this.http.get<FacilitySolarPerformance[]>(`${FACILITIES_URL}/${facilityId}/performance/solar`);
  }

  getFacilityPerformancesSolarByFacilityGroupId(facilityGroupId: number): Observable<FacilitySolarPerformance[]> {
    return this.http.get<FacilitySolarPerformance[]>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/performance/solar`);
  }
}
