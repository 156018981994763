import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { loadFacilitiesAtStartup } from 'brain-data/store/actions/facility.actions';
import { State } from 'reducers';

@Injectable({
  providedIn: 'root',
})
export class StartupService {
  constructor(private store: Store<State>) {}

  initializeApp() {
    this.store.dispatch(loadFacilitiesAtStartup());
  }
}
