import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Role } from 'brain-data/model/role.model';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

export const ROLES_ADMIN_URL = `${environment.apiBaseAdminUrl}/roles`;

@Injectable({
  providedIn: 'root',
})
export class RoleService {
  constructor(private http: HttpClient) {}

  getRoles(): Observable<Role[]> {
    return this.http.get<Role[]>(`${ROLES_ADMIN_URL}`);
  }
}
