import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilitySaving } from 'brain-data/model/utility.model';
import { Observable } from 'rxjs';

export const PUBLIC_URL = `${environment.apiBaseUrl}/public`;

@Injectable({
  providedIn: 'root',
})
export class PublicService {
  constructor(private http: HttpClient) {}

  getAllUtilityValuesSaving(fromYear: number, toYear: number): Observable<UtilitySaving> {
    const params = new HttpParams({ fromObject: { fromYear, toYear } });

    return this.http.get<UtilitySaving>(`${PUBLIC_URL}/utilities/savings`, { params });
  }
}
