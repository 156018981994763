import { createReducer, on } from '@ngrx/store';
import { togglePrintMode } from '@brain-portal/store/ui.action';

export default interface UIState {
  printMode: boolean;
}

export const initialState: UIState = {
  printMode: false,
};

export const reducer = createReducer(
  initialState,
  on(togglePrintMode, (state): UIState => ({ ...state, printMode: !state.printMode })),
);
