import { Injectable } from '@angular/core';
import { CUSTOMER_NAME } from 'constants/customer.constant';
import { TENANT } from 'constants/tenant.constant';

const PRODUCTION_TENANTS = [TENANT.coa];

@Injectable({
  providedIn: 'root',
})
export class TenantService {
  private readonly tenant: string;

  constructor() {
    this.tenant = this.getSubDomainFromUrl() ?? 'local';
  }

  getSubDomainFromUrl = (): string | undefined => {
    const urlParts = window.location.href.match(/^(https?:\/\/)?([^\/]+)(\/.*)?$/);
    return urlParts?.[2]?.match(/^([^.]+)/)?.[1];
  };

  getLogoNameFromTenant(anotherPicture: boolean): string {
    const prefixLogo = 'logo';

    const tenants = {
      [TENANT.coa]: CUSTOMER_NAME.albuquerque,
      [TENANT.demo]: CUSTOMER_NAME.gridwatch,
    };

    const customerName = tenants[this.tenant] || CUSTOMER_NAME.gridwatch;

    let logo = `${prefixLogo}-${customerName}`;

    if (anotherPicture || this.tenant !== TENANT.coa) {
      logo += '-2';
    }

    return logo;
  }

  getTenant() {
    return this.tenant;
  }

  isProductionTenant = () => PRODUCTION_TENANTS.includes(this.getTenant());
}
