export interface EmissionsData {
  emissionsPerYearMonth: { [key: string]: number };
  energyConsumption: number;
  totalEnergyCost: number;
  totalEmissions: number;
  averageCost: number;
  previousYearEnergyConsumption: number;
  previousYearTotalEnergyCost: number;
  previousYearTotalEmissions: number;
}

export interface EmissionsConsumptionAndCosts {
  energyConsumptionPerYearMonth: { [key: string]: number };
  totalCostPerYearMonth: { [key: string]: number };
}

export interface EmissionEquivalents {
  miles: number;
  trees: number;
  gasoline: number;
  homeElectricity: number;
}

export interface EmissionFigures {
  emissionsPerYearMonth: { [key: string]: number };
  energyConsumption: number;
  totalEnergyCost: number;
  totalEmissions: number;
  averageCost: number;
  previousYearEnergyConsumption: number;
  previousYearTotalEnergyCost: number;
  previousYearTotalEmissions: number;
  emissionChange: number;
  consumptionChange: number;
  costChange: number;
}

export const emptyEquivalents: EmissionEquivalents = { miles: 0, trees: 0, gasoline: 0, homeElectricity: 0 };
