import { createAction, props } from '@ngrx/store';
import { Utility, UtilitySaving } from 'brain-data/model/utility.model';

// UTILITIES

export const loadUtilitiesDetailByFacilityIdAndDateRange = createAction(
  '[Utilities] load utilities detail by facility id and range',
  props<{ facilityId: number; startDate: string; endDate: string }>(),
);
export const loadUtilitiesDetailByFacilityIdAndDateRangeSuccess = createAction(
  '[Utilities] load utilities detail by facility id and range success',
  props<{ utilitiesDetail: Utility[] }>(),
);
export const loadUtilitiesDetailByFacilityIdAndDateRangeError = createAction(
  '[App] [Utilities] load utilities detail by facility id and range error',
  props<{ error: Error }>(),
);

/// *****

export const loadUtilitiesDetailYearlyByFacilityIdAndDateRange = createAction(
  '[Utilities] load utilities detail yearly by facility id and range',
  props<{ facilityId: number; startDate: string; endDate: string }>(),
);
export const loadUtilitiesDetailYearlyByFacilityIdAndDateRangeSuccess = createAction(
  '[Utilities] load utilities detail yearly by facility id and range success',
  props<{ utilitiesDetail: Utility[] }>(),
);
export const loadUtilitiesDetailYearlyByFacilityIdAndDateRangeError = createAction(
  '[App] [Utilities] load utilities detail yearly by facility id and range error',
  props<{ error: Error }>(),
);

/// *****

export const loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRange = createAction(
  '[Utilities] load utilities detail yearly by facilityGroup id and range',
  props<{ facilityGroupId: number; startDate: string; endDate: string }>(),
);
export const loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeSuccess = createAction(
  '[Utilities] load utilities detail yearly by facilityGroup id and range success',
  props<{ utilitiesDetail: Utility[] }>(),
);
export const loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeError = createAction(
  '[Utilities] load utilities detail yearly by facilityGroup id and range error',
  props<{ error: Error }>(),
);

/// *****

export const loadUtilitiesDetailYearlyByDateRange = createAction(
  '[Utilities] load utilities detail yearly by range',
  props<{ startDate: string; endDate: string }>(),
);
export const loadUtilitiesDetailYearlyByDateRangeSuccess = createAction(
  '[Utilities] load utilities detail yearly by range success',
  props<{ utilitiesDetail: Utility[] }>(),
);
export const loadUtilitiesDetailYearlyByDateRangeError = createAction(
  '[App] [Utilities] load utilities detail yearly by range error',
  props<{ error: Error }>(),
);

export const loadUtilitiesDetailByDateRange = createAction('[Utilities] load utilities detail by range', props<{ startDate?: string; endDate?: string }>());
export const loadUtilitiesDetailByDateRangeSuccess = createAction(
  '[Utilities] load utilities detail by range success',
  props<{ utilitiesDetail: Utility[] }>(),
);
export const loadUtilitiesDetailByDateRangeError = createAction('[App] [Utilities] load utilities detail by range error', props<{ error: Error }>());

/// *****

export const loadUtilitiesDetailByFacilityGroupIdAndDateRange = createAction(
  '[Utilities] load utilities detail by facilityGroup Id and range',
  props<{ facilityGroupId: number; startDate: string; endDate: string }>(),
);
export const loadUtilitiesDetailByFacilityGroupIdAndDateRangeSuccess = createAction(
  '[Utilities] load utilities detail by facilityGroup Id and range success',
  props<{ utilitiesDetail: Utility[] }>(),
);
export const loadUtilitiesDetailByFacilityGroupIdAndDateRangeError = createAction(
  '[App] [Utilities] load utilities detail by facilityGroup Id and range error',
  props<{ error: Error }>(),
);

/// *****

export const loadUtilitySummariesByFacilityId = createAction(
  '[Utilities] load utility summaries by facility id',
  props<{ facilityId: number; startDate: string; endDate: string }>(),
);
export const loadUtilitySummariesByFacilityIdSuccess = createAction(
  '[Utilities] load utility summaries by facility id success',
  props<{ utilitySummaries: Utility[] }>(),
);
export const loadUtilitySummariesByFacilityIdError = createAction('[App] [Utilities] load utility summaries by facility id error', props<{ error: Error }>());

/// *****

export const loadUtilitySummariesByFacilityGroupId = createAction(
  '[Utilities] load utility summaries by facilityGroup id',
  props<{ facilityGroupId: number; startDate: string; endDate: string }>(),
);
export const loadUtilitySummariesByFacilityGroupIdSuccess = createAction(
  '[Utilities] load utility summaries by facilityGroup id success',
  props<{ utilitySummaries: Utility[] }>(),
);
export const loadUtilitySummariesByFacilityGroupIdError = createAction(
  '[App] [Utilities] load utility summaries by facilityGroup id error',
  props<{ error: Error }>(),
);

/// *****

export const loadUtilitySaving = createAction('[Utilities Saving] load utilities saving range', props<{ startYear: number; endYear: number }>());
export const loadUtilitySavingSuccess = createAction('[Utilities Saving] load utilities saving range success', props<{ utilitiesSaving: UtilitySaving }>());
export const loadUtilitySavingError = createAction('[Utilities Saving] load utilities saving range error', props<{ error: Error }>());

export const loadUtilitySummaries = createAction('[Utilities] load utility summaries', props<{ startDate: string; endDate: string }>());
export const loadUtilitySummariesSuccess = createAction('[Utilities] load utility summaries  success', props<{ utilitySummaries: Utility[] }>());
export const loadUtilitySummariesError = createAction('[App] [Utilities] load utility summaries  error', props<{ error: Error }>());
