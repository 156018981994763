import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Facility } from 'brain-data/model/facility.model';
import { environment } from '../../../../environments/environment';

export const FACILITIES_URL = `${environment.apiBaseUrl}/facilities`;
export const FACILITIES_ADMIN_URL = `${environment.apiBaseAdminUrl}/facilities`;

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  constructor(private http: HttpClient) {}

  getFacilities(): Observable<Facility[]> {
    return this.http.get<Facility[]>(FACILITIES_URL);
  }

  getFacilityById(facilityId?: number): Observable<Facility> {
    return this.http.get<Facility>(`${FACILITIES_URL}/${facilityId}`);
  }

  updateFacility(facilityId: number, facility: Facility): Observable<void> {
    return this.http.put<void>(`${FACILITIES_ADMIN_URL}/${facilityId}`, facility);
  }
}
