import { createAction, props } from '@ngrx/store';
import { EditUserRequest, User, UserDetails } from 'brain-data/model/users.model';

export const resetUser = createAction('[Users] Reset user');
export const resetLoadingUser = createAction('[Users] Reset loading user');

export const loadUsers = createAction('[Users] load users');
export const loadUsersSuccess = createAction('[Users] load users success', props<{ users: User[] }>());
export const loadUsersError = createAction('[Users] load users error', props<{ error: Error }>());

export const loadUser = createAction('[Users] load user', props<{ userId: string }>());
export const loadUserSuccess = createAction('[Users] load user success', props<{ user: User }>());
export const loadUserError = createAction('[Users] load user error', props<{ error: Error }>());

export const createUser = createAction('[Users] create user', props<{ email: string }>());
export const createUserSuccess = createAction('[Users] create user success', props<{ user: User }>());
export const createUserError = createAction('[Users] create user error', props<{ error: Error }>());

export const editUser = createAction('[Users] edit user', props<{ userId: string; editUserRequest: EditUserRequest }>());
export const editUserSuccess = createAction('[Users] edit user success', props<{ user: User }>());
export const editUserError = createAction('[Users] edit user error', props<{ error: Error }>());

export const archiveUser = createAction('[Users] archive user', props<{ userId: string }>());
export const archiveUserSuccess = createAction('[Users] archive user success');
export const archiveUserError = createAction('[Users] archive user error', props<{ error: Error }>());

export const loadUserDetails = createAction('[Users] load user details', props<{ userId: string }>());
export const loadUserDetailsSuccess = createAction('[Users]  load user details success', props<{ userDetails: UserDetails }>());
export const loadUserDetailsError = createAction('[Users]  load user details error', props<{ error: Error }>());

export const assignUserGroupPermissions = createAction('[Users] assign user group permissions', props<{ userId: string; facilityGroupsId: number[] }>());
export const assignUserGroupPermissionsSuccess = createAction('[Users]  assign user group permissions success');
export const assignUserGroupPermissionsError = createAction('[Users]  assign user group permissions error', props<{ error: Error }>());
