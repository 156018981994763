import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import FacilityState, * as fromData from '../brain-data/store/reducers/facility.reducer';
import UtilityState, * as fromUtility from '../brain-data/store/reducers/utility.reducer';
import AuthenticationState, * as fromAuthentication from '../brain-data/store/reducers/authentication.reducer';
import UtilityContractState, * as fromUtilityContract from '../brain-data/store/reducers/utility-contract.reducer';
import UtilityBillsState, * as fromUtilityBills from '../brain-data/store/reducers/utility-bills.reducer';
import LiveFeedState, * as fromExternalProvider from '../brain-data/store/reducers/external-provider.reducer';
import AlertState, * as fromAlert from '../brain-data/store/reducers/alert.reducer';
import SubSystemState, * as fromSubSystem from '../brain-data/store/reducers/sub-system.reducer';
import EmissionsState, * as fromEmissions from '../brain-data/store/reducers/emissions.reducer';
import ReportState, * as fromReport from 'brain-data/store/reducers/report.reducer';
import UserState, * as fromUser from 'brain-data/store/reducers/user.reducer';
import RoleState, * as fromRole from 'brain-data/store/reducers/role.reducer';

import UIState, * as fromUI from 'brain-portal/store/ui.reducer';

import { environment } from '../../environments/environment';

export interface State {
  alert: AlertState;
  facility: FacilityState;
  utility: UtilityState;
  utilityContract: UtilityContractState;
  utilityBills: UtilityBillsState;
  authentication: AuthenticationState;
  externalProvider: LiveFeedState;
  subSystem: SubSystemState;
  emissions: EmissionsState;
  report: ReportState;
  user: UserState;
  role: RoleState;
  ui: UIState;
}

export const reducers: ActionReducerMap<State> = {
  alert: fromAlert.reducer,
  facility: fromData.reducer,
  utility: fromUtility.reducer,
  utilityContract: fromUtilityContract.reducer,
  utilityBills: fromUtilityBills.reducer,
  authentication: fromAuthentication.reducer,
  externalProvider: fromExternalProvider.reducer,
  subSystem: fromSubSystem.reducer,
  emissions: fromEmissions.reducer,
  report: fromReport.reducer,
  user: fromUser.reducer,
  role: fromRole.reducer,
  ui: fromUI.reducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];
