<ng-container *ngIf="(facilityGroups$ | async)?.length === 0 && (facilities$ | async)?.length === 0 && isRouterToIncludeSpinner; else appBody">
  <div id="splash-screen">
    <div id="splash-logo-container">
      <img id="splash-logo" [src]="'./assets/images/logos/' + logoName + '.png'" alt="Logo" />
    </div>
  </div>
</ng-container>

<ng-template #appBody>
  <router-outlet></router-outlet>
</ng-template>
