import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  public constructor(private oAuthService: OAuthService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let req = request.clone();

    if (request.url.startsWith(environment.apiBaseUrl) && !request.url.includes('public')) {
      req = req.clone({
        setHeaders: {
          authorization: `Bearer ${this.oAuthService.getAccessToken()}`,
        },
      });
    }

    return next.handle(req);
  }
}
