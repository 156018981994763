import { createReducer, on } from '@ngrx/store';
import { ReportDetails, ReportPerformance, SummaryReport, UtilityReportData } from 'brain-data/model/report.model';
import {
  loadReportDetailsAndEmissionsForCity,
  loadReportDetailsForCitySuccess,
  loadReportDetailsAndEmissionsForFacility,
  loadReportDetailsForFacilityError,
  loadReportDetailsForFacilitySuccess,
  loadReportDetailsAndEmissionsForGroup,
  loadReportDetailsForGroupError,
  loadReportDetailsForGroupSuccess,
  loadReportPerformanceForFacilityError,
  loadReportPerformanceForFacilitySuccess,
  loadReportPerformanceForGroupError,
  loadReportPerformanceForGroupSuccess,
  loadSummaryReportForCity,
  loadSummaryReportForCityError,
  loadSummaryReportForCitySuccess,
  loadSummaryReportForGroup,
  loadSummaryReportForGroupError,
  loadSummaryReportForGroupSuccess,
} from '../actions/report.action';
import {
  getUtilityReportDataForCity, getUtilityReportDataForCityError,
  getUtilityReportDataForCitySuccess, getUtilityReportDataForFacility, getUtilityReportDataForFacilityError,
  getUtilityReportDataForFacilitySuccess,
  getUtilityReportDataForGroup, getUtilityReportDataForGroupError,
  getUtilityReportDataForGroupSuccess
} from '../actions/utility-report.actions';

export default interface ReportState {
  summaryReport?: SummaryReport;
  reportDetails?: ReportDetails;
  reportPerformance?: ReportPerformance;
  utilityReport?: UtilityReportData;
  summaryLoading: boolean;
  detailsLoading: boolean;
  performanceLoading: boolean;
  utilityReportLoading: boolean;
}

export const initialState: ReportState = {
  summaryReport: undefined,
  reportDetails: undefined,
  reportPerformance: undefined,
  utilityReport: undefined,
  summaryLoading: false,
  detailsLoading: false,
  performanceLoading: false,
  utilityReportLoading: false,
};

export const reducer = createReducer(
  initialState,
  on(
    loadSummaryReportForCity,
    (state): ReportState => ({
      ...state,
      summaryLoading: true,
      performanceLoading: false,
    }),
  ),
  on(
    loadSummaryReportForGroup,
    (state): ReportState => ({
      ...state,
      summaryLoading: true,
      performanceLoading: true,
    }),
  ),
  on(
    loadSummaryReportForCitySuccess,
    loadSummaryReportForGroupSuccess,
    (state, { summaryReport }): ReportState => ({
      ...state,
      summaryLoading: false,
      summaryReport,
    }),
  ),
  on(
    loadSummaryReportForCityError,
    loadSummaryReportForGroupError,
    (state): ReportState => ({
      ...state,
      summaryLoading: false,
    }),
  ),
  on(
    loadReportDetailsAndEmissionsForCity,
    (state): ReportState => ({
      ...state,
      detailsLoading: true,
    }),
  ),
  on(
    loadReportDetailsAndEmissionsForGroup,
    loadReportDetailsAndEmissionsForFacility,
    (state): ReportState => ({
      ...state,
      detailsLoading: true,
      performanceLoading: true,
    }),
  ),
  on(
    loadReportDetailsForCitySuccess,
    loadReportDetailsForGroupSuccess,
    loadReportDetailsForFacilitySuccess,
    (state, { reportDetails }): ReportState => ({
      ...state,
      detailsLoading: false,
      reportDetails,
    }),
  ),
  on(
    loadReportDetailsForGroupError,
    loadReportDetailsForFacilityError,
    (state): ReportState => ({
      ...state,
      detailsLoading: false,
    }),
  ),

  on(
    loadReportPerformanceForFacilitySuccess,
    loadReportPerformanceForGroupSuccess,
    (state, { reportPerformance }): ReportState => ({
      ...state,
      performanceLoading: false,
      reportPerformance,
    }),
  ),
  on(
    loadReportPerformanceForFacilityError,
    loadReportPerformanceForGroupError,
    (state): ReportState => ({
      ...state,
      performanceLoading: false,
    }),
  ),
  on(
    getUtilityReportDataForFacility,
    getUtilityReportDataForGroup,
    getUtilityReportDataForCity,
    (state): ReportState => ({
      ...state,
      utilityReportLoading: true,
    }),
  ),
  on(
    getUtilityReportDataForFacilitySuccess,
    getUtilityReportDataForGroupSuccess,
    getUtilityReportDataForCitySuccess,
    (state, { data }): ReportState => ({
      ...state,
      utilityReport: data,
      utilityReportLoading: false,
    }),
  ),
  on(
    getUtilityReportDataForFacilityError,
    getUtilityReportDataForGroupError,
    getUtilityReportDataForCityError,
    (state): ReportState => ({
      ...state,
      utilityReportLoading: false,
    }),
  ),
);
