import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, from, map, of, switchMap, tap } from 'rxjs';
import { UserService } from 'brain-data/service/users/user.service';
import {
  archiveUser,
  archiveUserError,
  archiveUserSuccess,
  assignUserGroupPermissions,
  assignUserGroupPermissionsError,
  assignUserGroupPermissionsSuccess,
  createUser,
  createUserError,
  createUserSuccess,
  editUser,
  editUserError,
  editUserSuccess,
  loadUser,
  loadUserDetails,
  loadUserDetailsError,
  loadUserDetailsSuccess,
  loadUserError,
  loadUsers,
  loadUsersError,
  loadUsersSuccess,
  loadUserSuccess,
} from '../actions/user.action';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable()
export class UserEffects {
  loadUsersEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUsers),
      switchMap(() =>
        this.userService.getUsers().pipe(
          map(users => loadUsersSuccess({ users })),
          catchError(error => of(loadUsersError({ error }))),
        ),
      ),
    );
  });

  loadUserEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUser),
      switchMap(({ userId }) =>
        this.userService.getUser(userId).pipe(
          map(user => loadUserSuccess({ user })),
          catchError(error => of(loadUserError({ error }))),
        ),
      ),
    );
  });

  loadUserDetailsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserDetails),
      switchMap(({ userId }) =>
        this.userService.getUserDetails(userId).pipe(
          map(userDetails => loadUserDetailsSuccess({ userDetails })),
          catchError(error => of(loadUserDetailsError({ error }))),
        ),
      ),
    );
  });

  assignUserGroupPermissionsEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignUserGroupPermissions),
      switchMap(({ userId, facilityGroupsId }) =>
        this.userService.assignUserGroupPermissions(userId, facilityGroupsId).pipe(
          map(() => assignUserGroupPermissionsSuccess()),
          catchError(error => of(assignUserGroupPermissionsError({ error }))),
        ),
      ),
    );
  });

  createUserEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(createUser),
      switchMap(({ email }) =>
        this.userService.createUser(email).pipe(
          map(user => createUserSuccess({ user })),
          catchError(error => of(createUserError({ error }))),
        ),
      ),
    );
  });

  editUserEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(editUser),
      switchMap(({ userId, editUserRequest }) =>
        this.userService.editUser(userId, editUserRequest).pipe(
          map(user => editUserSuccess({ user })),
          catchError(error => of(editUserError({ error }))),
        ),
      ),
    );
  });

  archiveUserEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(archiveUser),
      switchMap(({ userId }) =>
        this.userService.archiveUser(userId).pipe(
          map(() => archiveUserSuccess()),
          tap(() => this.dialog.closeAll()),
          switchMap(() => from(this.router.navigate(['/portal/settings/users'])).pipe(map(() => ({ type: '[Users] Navigation Success' })))),
          catchError(error => of(archiveUserError({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private router: Router,
    private dialog: MatDialog,
  ) {}
}
