import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { getDashboardlUrl } from 'shared/services/utils/url/url.service';

@Injectable({
  providedIn: 'root',
})
export class AuthLoginGuard {
  constructor(private oauthService: OAuthService, private router: Router) {
  }

  async canActivate(): Promise<boolean> {
    if (this.oauthService.hasValidAccessToken()) {
      await this.router.navigate([getDashboardlUrl()]);
      return false;
    }
    return true;
  }
}
