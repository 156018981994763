import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatedValue, Utility, UtilitySummary } from 'brain-data/model/utility.model';
import { map, Observable } from 'rxjs';
import { FACILITIES_URL } from '../facility/facility.service';
import { environment } from '../../../../environments/environment';
import { UtilityTransformService } from './utility-transform.service';

export const UTILITIES_URL = `${environment.apiBaseUrl}/utilities`;

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor(private http: HttpClient, private utilityTransformService: UtilityTransformService) {
  }

  getUtilitiesSummaryByFacilityId(facilityId: number, startDate: string, endDate: string): Observable<Utility[]> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<UtilitySummary>(`${FACILITIES_URL}/${facilityId}/utilities/summary`, { params }).pipe(
      map(utilitiesSummaryResponse =>
        this.utilityTransformService.convertUtilitySummariesToUtilities(utilitiesSummaryResponse)
      )
    );
  }

  getUtilitiesSummary(startDate: string, endDate: string): Observable<Utility[]> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<UtilitySummary>(`${UTILITIES_URL}/summary`, { params }).pipe(
      map(utilitiesSummaryResponse =>
        this.utilityTransformService.convertUtilitySummariesToUtilities(utilitiesSummaryResponse)
      )
    );
  }

  getUtilitiesDetailByFacilityId(facilityId: number, startDate: string, endDate: string) {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<Record<string, {
      usage: DatedValue[];
      spend: DatedValue[];
    }>>(`${FACILITIES_URL}/${facilityId}/utilities/detail`, { params });
  }

  getUtilitiesDetailByContractNumber(contractNumber: string, startDate: string, endDate: string) {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<Record<string, {
      usage: DatedValue[];
      spend: DatedValue[];
    }>>(`${UTILITIES_URL}/${contractNumber}/detail`, { params });
  }

  getUtilitiesDetail(startDate?: string, endDate?: string) {
    const params = new HttpParams({ fromObject: { from: startDate ?? '', to: endDate ?? '' } });
    return this.http.get<Record<string, {
      usage: DatedValue[];
      spend: DatedValue[];
    }>>(`${UTILITIES_URL}/detail`, { params });
  }

  getUtilitiesDetailsByFacilityIdAndDateRange(facilityId: number, startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailByFacilityId(facilityId, startDate, endDate).pipe(
      map(utilitiesDetailsResponse => this.utilityTransformService.filterUtilitiesDetailByDateRange(utilitiesDetailsResponse)),
      map(utilitiesDetailsFilteredResponse => this.utilityTransformService.convertUtilityDetailsToUtilities(utilitiesDetailsFilteredResponse))
    );
  }

  getUtilitiesDetailsByContractNumberAndDateRange(contractNumber: string, startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailByContractNumber(contractNumber, startDate, endDate).pipe(
      map(utilitiesDetailsResponse => this.utilityTransformService.filterUtilitiesDetailByDateRange(utilitiesDetailsResponse)),
      map(utilitiesDetailsFilteredResponse => this.utilityTransformService.convertUtilityDetailsToUtilities(utilitiesDetailsFilteredResponse))
    );
  }

  getUtilitiesYearlyByFacilityIdAndDateRange(facilityId: number, startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailsByFacilityIdAndDateRange(facilityId, startDate, endDate).pipe(
      map(utilities => this.utilityTransformService.filterUtilitiesToYearly(utilities)),
    );
  }

  getUtilitiesYearlyByContractNumberAndDateRange(contractNumber: string, startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailsByContractNumberAndDateRange(contractNumber, startDate, endDate).pipe(
      map(utilities => this.utilityTransformService.filterUtilitiesToYearly(utilities)),
    );
  }

  getUtilitiesDetailsByDateRange(startDate?: string, endDate?: string): Observable<Utility[]> {
    return this.getUtilitiesDetail(startDate, endDate).pipe(
      map(utilitiesDetailsResponse => this.utilityTransformService.filterUtilitiesDetailByDateRange(utilitiesDetailsResponse)),
      map(utilitiesDetailsFilteredResponse => this.utilityTransformService.convertUtilityDetailsToUtilities(utilitiesDetailsFilteredResponse)),
    );
  }

  getUtilitiesYearlyByDateRange(startDate: string, endDate: string): Observable<Utility[]> {
    return this.getUtilitiesDetailsByDateRange(startDate, endDate).pipe(
      map(utilities => this.utilityTransformService.filterUtilitiesToYearly(utilities)),
    );
  }
}
