import { createReducer, on } from '@ngrx/store';

import { Role } from 'brain-data/model/role.model';
import {
  assignUserRoleError,
  assignUserRole,
  assignUserRoleSuccess,
  loadRoles,
  loadRolesError,
  loadRolesSuccess,
  loadUserRoles,
  loadUserRolesError,
  loadUserRolesSuccess,
  resetRoles,
  resetRoleLoading,
} from '../actions/role.action';

export default interface RoleState {
  roles: Role[];
  rolesLoading?: boolean;
  userRoles: Role[];
  userRolesLoading?: boolean;
  userRoleLoading?: boolean;
  userRoleError?: Error;
  rolesError?: Error;
}

export const initialState: RoleState = {
  roles: [],
  rolesLoading: undefined,
  userRoles: [],
  userRolesLoading: undefined,
  userRoleLoading: undefined,
  userRoleError: undefined,
  rolesError: undefined,
};

export const reducer = createReducer(
  initialState,

  on(resetRoles, (state): RoleState => ({ ...state, userRoles: [], roles: [], rolesError: undefined })),
  on(resetRoleLoading, (state): RoleState => ({ ...state, userRoleLoading: undefined })),

  on(loadRoles, (state): RoleState => ({ ...state, rolesLoading: true, rolesError: undefined })),
  on(loadRolesSuccess, (state, { roles }): RoleState => ({ ...state, roles, rolesLoading: false })),
  on(loadRolesError, (state, { error }): RoleState => ({ ...state, rolesLoading: false, rolesError: error })),

  on(loadUserRoles, (state): RoleState => ({ ...state, userRolesLoading: true, rolesError: undefined })),
  on(loadUserRolesSuccess, (state, { roles }): RoleState => ({ ...state, userRoles: roles, userRolesLoading: false })),
  on(loadUserRolesError, (state, { error }): RoleState => ({ ...state, userRolesLoading: undefined, rolesError: error })),

  on(assignUserRole, (state): RoleState => ({ ...state, userRoleLoading: true, userRoleError: undefined })),
  on(assignUserRoleSuccess, (state): RoleState => ({ ...state, userRoleLoading: false })),
  on(assignUserRoleError, (state, { error }): RoleState => ({ ...state, userRoleLoading: false, userRoleError: error })),
);
