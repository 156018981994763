import { createAction, props } from '@ngrx/store';
import { ReportDetails, ReportPerformance, SummaryReport } from 'brain-data/model/report.model';

export const loadSummaryReportForCity = createAction('[Report Summary] Load Report Summary for City', props<{ from: string; to: string }>());
export const loadSummaryReportForCitySuccess = createAction('[Report Summary] Load Report Summary for City Success', props<{ summaryReport: SummaryReport }>());
export const loadSummaryReportForCityError = createAction('[Report Summary] Load Report Summary for City Error', props<{ error: Error }>());

export const loadSummaryReportForGroup = createAction(
  '[Report Summary] Load Report Summary for Group',
  props<{ facilityGroupId: number; from: string; to: string }>(),
);
export const loadSummaryReportForGroupSuccess = createAction(
  '[Report Summary] Load Report Summary for Group Success',
  props<{ summaryReport: SummaryReport }>(),
);
export const loadSummaryReportForGroupError = createAction('[Report Summary] Load Report Summary for Group Error', props<{ error: Error }>());

export const loadReportDetailsAndEmissionsForCity = createAction('[Report Details] Load Report Details for City', props<{ from: string; to: string }>());
export const loadReportDetailsForCitySuccess = createAction('[Report Details] Load Report Details for City Success', props<{ reportDetails: ReportDetails }>());
export const loadReportDetailsForCityError = createAction('[Report Details] Load Report Details for City Error', props<{ error: Error }>());

export const loadReportDetailsAndEmissionsForFacility = createAction(
  '[Report Details] Load Report Details for Facility',
  props<{ facilityId: number; from: string; to: string }>(),
);
export const loadReportDetailsForFacilitySuccess = createAction(
  '[Report Details] Load Report Details for Facility Success',
  props<{ reportDetails: ReportDetails }>(),
);
export const loadReportDetailsForFacilityError = createAction('[Report Details] Load Report Details for Facility Error', props<{ error: Error }>());

export const loadReportDetailsAndEmissionsForGroup = createAction(
  '[Report Details] Load Report Details for Group',
  props<{ facilityGroupId: number; from: string; to: string }>(),
);
export const loadReportDetailsForGroupSuccess = createAction(
  '[Report Details] Load Report Details for Group Success',
  props<{ reportDetails: ReportDetails }>(),
);
export const loadReportDetailsForGroupError = createAction('[Report Details] Load Report Details for Group Error', props<{ error: Error }>());

export const loadReportPerformanceForGroupSuccess = createAction(
  '[Report Summary] Load Report Performance for Group Success',
  props<{ reportPerformance: ReportPerformance }>(),
);
export const loadReportPerformanceForGroupError = createAction('[Report Summary] Load Report Performance for Group Error', props<{ error: Error }>());

export const loadReportPerformanceForFacilitySuccess = createAction(
  '[Report Summary] Load Report Performance for Facility Success',
  props<{ reportPerformance: ReportPerformance }>(),
);
export const loadReportPerformanceForFacilityError = createAction('[Report Summary] Load Report Performance for Facility Error', props<{ error: Error }>());
