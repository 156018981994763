import { createAction, props } from '@ngrx/store';
import { EmissionsData } from 'brain-data/model/emissions.model';

export const loadEmissionsForCity = createAction('[Emissions] Load Emissions for City', props<{ from: string; to: string }>());
export const loadEmissionsForCitySuccess = createAction('[Emissions] Load Emissions for City Success', props<{ emissionsData: EmissionsData }>());
export const loadEmissionsForCityError = createAction('[Emissions] Load Emissions for City Error', props<{ error: Error }>());

export const loadEmissionsForGroup = createAction('[Emissions] Load Emissions for Group', props<{ facilityGroupId: number; from: string; to: string }>());
export const loadEmissionsForGroupSuccess = createAction('[Emissions] Load Emissions for Group Success', props<{ emissionsData: EmissionsData }>());
export const loadEmissionsForGroupError = createAction('[Emissions] Load Emissions for Group Error', props<{ error: Error }>());

export const loadEmissionsForFacility = createAction('[Emissions] Load Emissions for Facility', props<{ facilityId: number; from: string; to: string }>());
export const loadEmissionsForFacilitySuccess = createAction('[Emissions] Load Emissions for Facility Success', props<{ emissionsData: EmissionsData }>());
export const loadEmissionsForFacilityError = createAction('[Emissions] Load Emissions for Facility Error', props<{ error: Error }>());
