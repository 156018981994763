import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { UserService } from 'brain-data/service/users/user.service';
import { RoleService } from 'brain-data/service/roles/role.service';
import {
  assignUserRole,
  assignUserRoleError,
  assignUserRoleSuccess,
  loadRoles,
  loadRolesError,
  loadRolesSuccess,
  loadUserRoles,
  loadUserRolesError,
  loadUserRolesSuccess,
} from '../actions/role.action';

@Injectable()
export class RoleEffects {
  loadRolesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadRoles),
      switchMap(() =>
        this.roleService.getRoles().pipe(
          map(roles => loadRolesSuccess({ roles })),
          catchError(error => of(loadRolesError({ error }))),
        ),
      ),
    );
  });

  loadUserRolesEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadUserRoles),
      switchMap(({ userId }) =>
        this.userService.getUserRoles(userId).pipe(
          map(roles => loadUserRolesSuccess({ roles })),
          catchError(error => of(loadUserRolesError({ error }))),
        ),
      ),
    );
  });

  insertUserRoleEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(assignUserRole),
      switchMap(({ userId, roleIds, roleNames }) =>
        this.userService.assignUserRoles(userId, roleIds, roleNames).pipe(
          map(() => assignUserRoleSuccess()),
          catchError(error => of(assignUserRoleError({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private userService: UserService,
    private roleService: RoleService,
  ) {}
}
