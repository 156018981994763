import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { ExternalProviderService } from '../../service/external-provider/external-provider.service';
import {
  loadHardwareForFacility,
  loadHardwareForFacilityError,
  loadHardwareForFacilitySuccess,
  loadAirQualityError,
  loadAirQualitySuccess,
  loadLiveWeatherSuccess,
  loadLiveWeatherError,
  loadDashboardAirData,
} from '../actions/external-provider.action';

@Injectable()
export class ExternalProviderEffects {
  loadHardwareEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadHardwareForFacility),
      switchMap(({ facilityId }) =>
        this.externalProviderService.getHardwareForFacility(facilityId).pipe(
          map(hardwareList => loadHardwareForFacilitySuccess({ hardwareList })),
          catchError(error => of(loadHardwareForFacilityError({ error })))
        )
      )
    );
  });

  loadAirQualityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDashboardAirData),
      switchMap(() =>
        this.externalProviderService.getAirQuality(35.08763, -106.65167).pipe(
          map(airQualityData => loadAirQualitySuccess({ airQualityData })),
          catchError(error => of(loadAirQualityError({ error })))
        )
      )
    );
  });

  loadLiveWeatherEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadDashboardAirData),
      switchMap(() =>
        this.externalProviderService.getLiveWeather('Albuquerque').pipe(
          map(liveWeather => loadLiveWeatherSuccess({ liveWeather })),
          catchError(error => of(loadLiveWeatherError({ error })))
        )
      )
    );
  });

  constructor(private actions$: Actions, private externalProviderService: ExternalProviderService) {}
}
