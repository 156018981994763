import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { AlertNotification } from 'brain-data/model/alert.model';

export const ALERTS_URL = `${environment.apiBaseUrl}/alerts`;

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  constructor(private http: HttpClient) {}

  snooze = (alertId: number, days: number) => {
    const params = new HttpParams({ fromObject: { days } });
    return this.http.post<void>(`${ALERTS_URL}/${alertId}:snooze`, {}, { params });
  };

  notify = (alertId: number, alertNotification: AlertNotification) => {
    return this.http.post<void>(`${ALERTS_URL}/${alertId}:notify`, alertNotification);
  };

  notifyAndSnooze = (alertId: number, days: number, alertNotify: AlertNotification) => {
    const params = new HttpParams({ fromObject: { days } });

    return this.http.post<void>(`${ALERTS_URL}/${alertId}:notifyAndSnooze`, alertNotify, { params });
  };
}
