import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ExternalProviderService } from '../../service/external-provider/external-provider.service';
import { loadAirQualityError } from '../actions/external-provider.action';
import { tap } from 'rxjs';

@Injectable()
export class ErrorNotifitcationEffects {
  handleServiceError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadAirQualityError),
      tap(({ error }) => console.error(error.message))
    );
  }, { dispatch: false });

  constructor(private actions$: Actions, private externalProviderService: ExternalProviderService) {}
}
