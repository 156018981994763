import { createReducer, on } from '@ngrx/store';
import {
  loadTotalUtilityContractsSuccess,
  loadUtilityContractsWithFacilityUnassigned,
  loadUtilityContractsWithFacilityUnassignedError,
  loadUtilityContractsWithFacilityUnassignedSuccess,
  updateAndAssignFacilityToUtilityContract,
  updateAndAssignFacilityToUtilityContractError,
  updateAndAssignFacilityToUtilityContractSuccess,
  updateAndUnAssignFacilityToUtilityContracts,
  updateAndUnAssignFacilityToUtilityContractsError,
  updateAndUnAssignFacilityToUtilityContractsSuccess,
  updateUtilityContracts,
  updateUtilityContractsError,
  updateUtilityContractsSuccess,
} from '../actions/utility-contract.action';
import { UtilityContract } from 'brain-data/model/utility-contract.model';

export default interface UtilityContractState {
  isLoading: boolean;
  isSavingLoading?: boolean;
  utilityContracts?: UtilityContract[];
  totalUtilityContracts?: number;
}

export const initialState: UtilityContractState = {
  isLoading: false,
  isSavingLoading: undefined,
  utilityContracts: [],
  totalUtilityContracts: undefined,
};

export const reducer = createReducer(
  initialState,
  on(loadUtilityContractsWithFacilityUnassigned, (state): UtilityContractState => ({ ...state, utilityContracts: [], isLoading: true })),
  on(
    loadUtilityContractsWithFacilityUnassignedSuccess,
    (state, { utilityContracts }): UtilityContractState => ({ ...state, utilityContracts, isLoading: false }),
  ),
  on(loadUtilityContractsWithFacilityUnassignedError, (state): UtilityContractState => ({ ...state, utilityContracts: [], isLoading: false })),
  on(loadTotalUtilityContractsSuccess, (state, { totalUtilityContracts }): UtilityContractState => ({ ...state, totalUtilityContracts })),
  on(
    updateUtilityContracts,
    updateAndUnAssignFacilityToUtilityContracts,
    updateAndAssignFacilityToUtilityContract,
    (state): UtilityContractState => ({ ...state, isSavingLoading: true }),
  ),
  on(
    updateUtilityContractsSuccess,
    updateAndUnAssignFacilityToUtilityContractsSuccess,
    updateAndAssignFacilityToUtilityContractSuccess,
    (state): UtilityContractState => ({ ...state, isSavingLoading: false }),
  ),
  on(
    updateUtilityContractsError,
    updateAndUnAssignFacilityToUtilityContractsError,
    updateAndAssignFacilityToUtilityContractError,
    (state): UtilityContractState => ({ ...state, isSavingLoading: false }),
  ),
);
