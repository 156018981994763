import { createAction, props } from '@ngrx/store';
import { AlsoEnergyHardware } from 'brain-data/model/alsoEnergy.model';
import { AirQualityData } from 'brain-data/model/airQuality.model';
import { LiveWeather } from 'brain-data/model/liveWeather.model';
export const resetLoading = createAction('[ExternalProvider] Reset Loading');

export const loadHardwareForFacility = createAction('[Hardware] Load inverters and production meters for facility', props<{ facilityId: number }>());
export const loadHardwareForFacilitySuccess = createAction(
  '[Hardware] Load inverters and production meters for facility Success',
  props<{ hardwareList: AlsoEnergyHardware[] }>()
);
export const loadHardwareForFacilityError = createAction('[Hardware] Load inverters and production meters for facility Error', props<{ error: Error }>());

export const loadDashboardAirData = createAction('[Dashboard Air Data] Load Dashboard Air Data');
export const loadLiveWeatherSuccess = createAction('[Live Weather] Load Live Weather Success', props<{ liveWeather: LiveWeather }>());
export const loadLiveWeatherError = createAction('[Live Weather] Load Live Weather Error', props<{ error: Error }>());
export const loadAirQualitySuccess = createAction('[Air Quality] Load Air Quality Success', props<{ airQualityData: AirQualityData }>());
export const loadAirQualityError = createAction('[Air Quality] Load Air Quality Error', props<{ error: Error }>());
