import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';
import { catchError, from, map, Observable } from 'rxjs';
import { ChallengeResponse } from '@brain-auth/messages/auth-mfa-associate-response';
import { AuthMfaInformation } from '@brain-auth/models/auth-mfa-information.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(
    private http: HttpClient,
    private oAuthService: OAuthService,
  ) {}

  login(username: string, password: string) {
    return from(this.oAuthService.fetchTokenUsingPasswordFlowAndLoadUserProfile(username, password));
  }

  askForPasswordChange(email: string) {
    const body = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.authClientId,
      email,
      connection: 'Username-Password-Authentication',
    };

    return this.http.post<string>(`${environment.authUrl}/dbconnections/change_password`, body);
  }

  askForChallenge = (mfaToken: string, email: string) =>
    this.fetchMfaAssociate(mfaToken, email).pipe(
      map(response => {
        return response;
      }),
      catchError((error: any, caught: Observable<ChallengeResponse>) => {
        if (error?.error?.error_description === 'User is already enrolled.') {
          return this.fetchMfaChallenge(mfaToken);
        } else {
          return caught;
        }
      }),
    );

  fetchMfaAssociate(mfaToken: string, email: string) {
    const headers = new HttpHeaders({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      Authorization: `Bearer ${mfaToken}`,
    });

    const body = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      authenticator_types: ['oob'],
      // eslint-disable-next-line @typescript-eslint/naming-convention
      oob_channels: ['email'],
      email: email,
    };

    return this.http.post<ChallengeResponse>(`${environment.authUrl}/mfa/associate`, body, { headers });
  }

  fetchMfaChallenge(mfaToken: string) {
    const body = {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      challenge_type: 'oob',
      // eslint-disable-next-line @typescript-eslint/naming-convention
      client_id: environment.authClientId,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      mfa_token: mfaToken,
    };

    return this.http.post<ChallengeResponse>(`${environment.authUrl}/mfa/challenge`, body);
  }

  loginMfa(authMfaInformation: AuthMfaInformation) {
    return from(
      this.oAuthService.fetchTokenUsingGrant('http://auth0.com/oauth/grant-type/mfa-oob', {
        mfa_token: authMfaInformation.mfaToken,
        oob_code: authMfaInformation.oobCode,
        binding_code: authMfaInformation.bindingCode,
        client_id: environment.authClientId,
      }),
    );
  }

  loadUserProfile() {
    return from(this.oAuthService.loadUserProfile());
  }

  userAuthenticated() {
    const rolesClaim = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/roles';
    const claims = this.oAuthService.getIdentityClaims();
    if (claims && claims[rolesClaim]) {
      const roles: string[] = claims[rolesClaim];
      return {
        ...claims,
        roles,
      };
    }
    return null;
  }
}
