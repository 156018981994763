import { Injectable } from '@angular/core';

const START_YEAR = 2012;

@Injectable({
  providedIn: 'root',
})
export class DateService {
  constructor() {}

  getYearsRange = (startYear: number, endYear: number): number[] => {
    const years: number[] = [];
    for (let year = startYear; year <= endYear; year++) {
      years.push(year);
    }
    return years;
  };

  getYearsRangeUpToCurrentAsNumbers = () => this.getYearsRange(START_YEAR, this.getCurrentYear());
  getYearsRangeUpToCurrent = () => this.getYearsRangeUpToCurrentAsNumbers().map(year => year.toString());
  getMonthsRange = () => this.getMonthKeysAndValues().map(month => month.value);

  getCurrentYear = (): number => new Date().getFullYear();

  getRealLifeMonthNumber = (): string => (new Date().getMonth() + 1).toString().padStart(2, '0');

  getYearFromDate(date: string): string {
    return date.substring(0, 4);
  }

  getMonthFromDate(date: string): string {
    return date.substring(5);
  }

  getMonthKeysAndValues(): { name: string; value: string }[] {
    return [
      { name: 'January', value: '01' },
      { name: 'February', value: '02' },
      { name: 'March', value: '03' },
      { name: 'April', value: '04' },
      { name: 'May', value: '05' },
      { name: 'June', value: '06' },
      { name: 'July', value: '07' },
      { name: 'August', value: '08' },
      { name: 'September', value: '09' },
      { name: 'October', value: '10' },
      { name: 'November', value: '11' },
      { name: 'December', value: '12' },
    ];
  }

  getDateFromMonthAndYear(monthDate: string, yearDate: string): Date {
    return new Date(parseInt(yearDate, 10), parseInt(monthDate, 10), 1);
  }

  convertToTextMonths = (yearMonths: string[]): string[] => yearMonths.map(this.convertToTextMonth);

  convertToTextMonth = (yearMonth?: string): string => {
    if (!yearMonth) {
      return '';
    }
    const components = yearMonth.split('-');
    const monthNo = Number(components[1]);
    const date = new Date(Number(components[0]), monthNo - 1, 1);
    return `${date.toLocaleString('en-US', { month: 'short' })} ’${components[0].substring(2)}`;
  };
}
