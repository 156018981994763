import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { debounce, interval, tap } from 'rxjs';
import { togglePrintMode } from '@brain-portal/store/ui.action';
import { State } from '../../reducers';
import { Store } from '@ngrx/store';
import { selectPrintMode } from '@brain-portal/store/ui.selector';

@Injectable()
export class UIEffects {
  printEffect$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(togglePrintMode),
        concatLatestFrom(() => [this.store.select(selectPrintMode)]),
        debounce(() => interval(1000)),
        tap(([, printMode]) => {
          if (printMode) {
            window.print();
          }
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
  ) {}
}
