import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilityContract } from 'brain-data/model/utility-contract.model';
import { UpdateUtilityContractRequest } from 'brain-data/message/update-utility-contract-request.model';

export const UTILITIES_CONTRACTS_URL = `${environment.apiBaseUrl}/utilityContracts`;
export const UTILITIES_CONTRACTS_ADMIN_URL = `${environment.apiBaseAdminUrl}/utilityContracts`;

@Injectable({
  providedIn: 'root',
})
export class UtilityContractService {
  constructor(private http: HttpClient) {}

  getUtilityContractsWithFacilityUnassigned(): Observable<UtilityContract[]> {
    return this.http.get<UtilityContract[]>(`${UTILITIES_CONTRACTS_URL}/facility/unassigned`);
  }

  getTotalUtilityContracts(): Observable<number> {
    return this.http.get<number>(`${UTILITIES_CONTRACTS_URL}/count`);
  }

  updateAndAssignFacilityToUtilityContract(utilityContractNumber: string, facilityId: number): Observable<void> {
    const params = new HttpParams({ fromObject: { facilityId } });

    return this.http.put<void>(`${UTILITIES_CONTRACTS_URL}/${utilityContractNumber}/assign/facility`, params);
  }

  updateAndUnAssignFacilityToUtilityContracts(utilityContracts: UtilityContract[]): Observable<void> {
    return this.http.put<void>(`${UTILITIES_CONTRACTS_ADMIN_URL}:unassign-facility`, utilityContracts);
  }

  updateUtilityContracts(facilityId: number, updateUtilityContractRequest: UpdateUtilityContractRequest[]): Observable<void> {
    return this.http.put<void>(`${UTILITIES_CONTRACTS_ADMIN_URL}/facility/${facilityId}`, updateUtilityContractRequest);
  }
}
