import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ReportDetails, ReportPerformance, SummaryReport } from 'brain-data/model/report.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FACILITY_GROUPS_URL } from '../facility-group/facility-group.service';
import { FACILITIES_URL } from '../facility/facility.service';

export const REPORT_URL = `${environment.apiBaseUrl}/report`;

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  constructor(private http: HttpClient) {}

  getSummaryReportForCity(startDate: string, endDate: string): Observable<SummaryReport> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<SummaryReport>(`${REPORT_URL}/summary`, { params });
  }

  getSummaryReportForGroup(facilityGroupId: number, startDate: string, endDate: string): Observable<SummaryReport> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<SummaryReport>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/report/summary`, { params });
  }

  getReportDetailsForCity(startDate: string, endDate: string): Observable<ReportDetails> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<ReportDetails>(`${REPORT_URL}/details`, { params });
  }

  getReportDetailsForFacility(facilityId: number, startDate: string, endDate: string): Observable<ReportDetails> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<ReportDetails>(`${FACILITIES_URL}/${facilityId}/report/details`, { params });
  }

  getReportDetailsForGroup(facilityGroupId: number, startDate: string, endDate: string): Observable<ReportDetails> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<ReportDetails>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/report/details`, { params });
  }

  getReportPerformanceForGroup(facilityGroupId: number, startDate: string, endDate: string): Observable<ReportPerformance> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<ReportPerformance>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/report/performance`, { params });
  }

  getReportPerformanceForFacility(facilityId: number, startDate: string, endDate: string): Observable<ReportPerformance> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<ReportPerformance>(`${FACILITIES_URL}/${facilityId}/report/performance`, { params });
  }
}
