export const UTILITY_TYPE = {
  electricity: 'ELECTRICITY',
  gas: 'GAS',
  solar: 'SOLAR',
  water: 'WATER',
} as const;
export type UtilityType = (typeof UTILITY_TYPE)[keyof typeof UTILITY_TYPE];

export const UTILITY_NAME = {
  electricity: 'electricity',
  water: 'water',
  gas: 'gas',
  solar: 'solar',
};

export const UTILITY_ICON = {
  electricity: 'electric_bolt',
  water: 'water_drop',
  gas: 'propane_tank',
  solar: 'solar_power',
};

export const UTILITY_BILLS_STATUS = {
  completed: 'COMPLETED',
  partial: 'PARTIAL',
  failed: 'FAILED',
} as const;

export const UTILITY_UNIT = {
  kwh: 'kWh',
  gallons: 'Gallons',
  mmbtu: 'MMBtu',
  tons: 'Metric Tons',
  co2e: 'CO2e'
};

export const UTILITY_CURRENCY = {
  dollars: '$',
};

export const UTILITY_ENVIRONMENTAL_IMPACT_FACTORS = {
  kgCo2ePerGallonWater: 0.34,
  kgCo2ePerKWh: 0.433,
  kgCo2ePerMMBtu: 52.9,
  metricTonPerKg: 0.001,
  metricTonsCo2ePerTree: 0.06,
  metricTonsCo2ePerGallonGasoline: 8.887e-3,
  metricTonsCo2ePerMile: 3.9e-4,
  metricTonsCo2ePerHomeElectricityYearly: 5.139,
  kWhPerSmartphoneChargedYearly: 4.383,
  kWhPerHomeElectricityYearly: 11880,
  gallonsPerOlympicPool: 490000,
  mmBtuPerGasolinePoweredVehicleYearly: 84.6211835658,
};
