import { createAction, props } from '@ngrx/store';
import { AlertNotification } from 'brain-data/model/alert.model';

export const resetAlertLoading = createAction('[Alert] Reset Alert loading');

export const resetAlertType = createAction('[Alert] Reset Alert');
export const updateAlertType = createAction('[Alert] Update Alert', props<{ alertType: string }>());

export const notifyAlert = createAction('[Alert] notify', props<{ alertId: number; alertNotification: AlertNotification }>());
export const notifyAlertSuccess = createAction('[Alert] notify success');
export const notifyAlertError = createAction('[Alert] notify error', props<{ error: Error }>());

export const snoozeAlert = createAction('[Alert] snooze', props<{ alertId: number; days: number }>());
export const snoozeAlertSuccess = createAction('[Alert] Snooze Alert Success', props<{ alertId: number; days: number }>());
export const snoozeAlertError = createAction('[Alert] snooze error', props<{ error: Error }>());

export const notifyAndSnoozeAlert = createAction('[Alert] notify and snooze', props<{ alertId: number; days: number; alertNotification: AlertNotification }>());
export const notifyAndSnoozeAlertSuccess = createAction(
  '[Alert] Notify and Snooze Alert Success',
  props<{ alertId: number; days: number; alertNotification: AlertNotification }>(),
);
export const notifyAndSnoozeAlertError = createAction('[Alert] notify and snooze  error', props<{ error: Error }>());
