import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { EmissionsService } from 'brain-data/service/emissions/emissions.service';
import {
  loadEmissionsForCity,
  loadEmissionsForCityError,
  loadEmissionsForCitySuccess,
  loadEmissionsForFacility,
  loadEmissionsForFacilityError,
  loadEmissionsForFacilitySuccess,
  loadEmissionsForGroup,
  loadEmissionsForGroupError,
  loadEmissionsForGroupSuccess,
} from '../actions/emissions.action';
import { loadReportDetailsAndEmissionsForCity, loadReportDetailsAndEmissionsForFacility, loadReportDetailsAndEmissionsForGroup } from '../actions/report.action';

@Injectable()
export class EmissionsEffects {
  loadEmissionsForCityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEmissionsForCity, loadReportDetailsAndEmissionsForCity),
      switchMap(({ from, to }) =>
        this.emissionsService.getEmissionsForCity(from, to).pipe(
          map(emissionsData => loadEmissionsForCitySuccess({ emissionsData })),
          catchError(error => of(loadEmissionsForCityError({ error }))),
        ),
      ),
    );
  });

  loadEmissionsForGroupEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEmissionsForGroup, loadReportDetailsAndEmissionsForGroup),
      switchMap(({ facilityGroupId, from, to }) =>
        this.emissionsService.getEmissionsForGroup(facilityGroupId, from, to).pipe(
          map(emissionsData => loadEmissionsForGroupSuccess({ emissionsData })),
          catchError(error => of(loadEmissionsForGroupError({ error }))),
        ),
      ),
    );
  });

  loadEmissionsForFacilityEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadEmissionsForFacility, loadReportDetailsAndEmissionsForFacility),
      switchMap(({ facilityId, from, to }) =>
        this.emissionsService.getEmissionsForFacility(facilityId, from, to).pipe(
          map(emissionsData => loadEmissionsForFacilitySuccess({ emissionsData })),
          catchError(error => of(loadEmissionsForFacilityError({ error }))),
        ),
      ),
    );
  });

  constructor(
    private actions$: Actions,
    private emissionsService: EmissionsService,
  ) {}
}
