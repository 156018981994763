import { createReducer, on } from '@ngrx/store';
import { notifyAlsoEnergyDown, notifyAlsoEnergyUp } from '../actions/sub-system.action';

export default interface SubSystemState {
  alsoEnergy: boolean;
  brainWaves: boolean;
}

export const initialState: SubSystemState = {
  alsoEnergy: true,
  brainWaves: true,
};

export const reducer = createReducer(
  initialState,
  on(notifyAlsoEnergyDown, (state): SubSystemState => ({
    ...state,
    alsoEnergy: false
  })),
  on(notifyAlsoEnergyUp, (state): SubSystemState => ({
    ...state,
    alsoEnergy: true
  })),
);
