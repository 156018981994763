import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityType } from 'constants/utility.constant';
import { FACILITY_GROUPS_URL } from '../facility-group/facility-group.service';
import { FACILITIES_URL } from '../facility/facility.service';
import { environment } from '../../../../environments/environment';
import { UtilityReportData } from '../../model/report.model';

@Injectable({
  providedIn: 'root',
})
export class UtilityReportService {
  constructor(private http: HttpClient) {}

  downloadFacilityUtilityReport(
    facilityId: number,
    startDate: string | undefined,
    endDate: string | undefined,
    type: UtilityType | undefined,
  ): Observable<Blob> {
    let paramsObject: any = { from: startDate ?? '', to: endDate ?? '' };
    if (type) {
      paramsObject = { ...paramsObject, type };
    }
    const params = new HttpParams({ fromObject: paramsObject });
    return this.http.get(`${FACILITIES_URL}/${facilityId}/utilities/report`, {
      params,
      headers: {},
      responseType: 'blob',
    });
  }

  downloadUtilityContractReport(
    facilityId: number,
    utilityContractNumber: string,
    startDate: string | undefined,
    endDate: string | undefined,
  ): Observable<Blob> {
    const paramsObject: any = { from: startDate ?? '', to: endDate ?? '' };

    const params = new HttpParams({ fromObject: paramsObject });
    return this.http.get(`${FACILITIES_URL}/${facilityId}/utilityContracts/${utilityContractNumber}/utilities/report`, {
      params,
      headers: {},
      responseType: 'blob',
    });
  }

  downloadFacilityGroupUtilityReport(
    facilityGroupId: number,
    startDate: string | undefined,
    endDate: string | undefined,
    type: UtilityType | undefined,
  ): Observable<Blob> {
    let paramsObject: any = { from: startDate ?? '', to: endDate ?? '' };
    if (type) {
      paramsObject = { ...paramsObject, type };
    }
    const params = new HttpParams({ fromObject: paramsObject });
    return this.http.get(`${FACILITY_GROUPS_URL}/${facilityGroupId}/utilities/report`, {
      params,
      headers: {},
      responseType: 'blob',
    });
  }

  downloadCityUtilityReport(startDate: string | undefined, endDate: string | undefined, type: UtilityType | undefined): Observable<Blob> {
    let paramsObject: any = { from: startDate ?? '', to: endDate ?? '' };
    if (type) {
      paramsObject = { ...paramsObject, type };
    }
    const params = new HttpParams({ fromObject: paramsObject });
    return this.http.get(`${environment.apiBaseUrl}/utilities/report`, {
      params,
      headers: {},
      responseType: 'blob',
    });
  }

  getFacilityUtilityReportData(facilityId: number, startDate: string, endDate: string): Observable<UtilityReportData> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<UtilityReportData>(`${FACILITIES_URL}/${facilityId}/utilities/data`, { params });
  }

  getFacilityGroupUtilityReportData(facilityGroupId: number, startDate: string, endDate: string): Observable<UtilityReportData> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });

    return this.http.get<UtilityReportData>(`${FACILITY_GROUPS_URL}/${facilityGroupId}/utilities/data`, { params });
  }

  getCityUtilityReportData(startDate: string, endDate: string): Observable<UtilityReportData> {
    const params = new HttpParams({ fromObject: { from: startDate, to: endDate } });
    return this.http.get<UtilityReportData>(`${environment.apiBaseUrl}/utilities/data`, { params });
  }
}
