import { createReducer, on } from '@ngrx/store';
import { Utility, UtilitySaving } from 'brain-data/model/utility.model';
import {
  loadUtilitiesDetailByFacilityIdAndDateRange,
  loadUtilitiesDetailYearlyByFacilityIdAndDateRange,
  loadUtilitiesDetailByFacilityGroupIdAndDateRange,
  loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRange,
  loadUtilitiesDetailByDateRange,
  loadUtilitiesDetailYearlyByDateRange,
  loadUtilitySummaries,
  loadUtilitySummariesByFacilityId,
  loadUtilitySummariesByFacilityGroupId,
  loadUtilitySummariesSuccess,
  loadUtilitySummariesByFacilityIdSuccess,
  loadUtilitySummariesByFacilityGroupIdSuccess,
  loadUtilitiesDetailByFacilityIdAndDateRangeSuccess,
  loadUtilitiesDetailYearlyByFacilityIdAndDateRangeSuccess,
  loadUtilitiesDetailByFacilityGroupIdAndDateRangeSuccess,
  loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeSuccess,
  loadUtilitiesDetailByDateRangeSuccess,
  loadUtilitiesDetailYearlyByDateRangeSuccess,
  loadUtilitySaving,
  loadUtilitySavingSuccess,
  loadUtilitySavingError,
} from '../actions/utility.actions';

export default interface UtilityState {
  utilitySummaries?: Utility[];
  utilitiesDetail?: Utility[];
  utilitiesSaving?: UtilitySaving;
  error?: Error;
}

export const initialState: UtilityState = {
  utilitySummaries: undefined,
  utilitiesDetail: undefined,
  utilitiesSaving: undefined,
  error: undefined,
};

export const reducer = createReducer(
  initialState,
  on(
    loadUtilitiesDetailByFacilityIdAndDateRange,
    loadUtilitiesDetailYearlyByFacilityIdAndDateRange,
    loadUtilitiesDetailByFacilityGroupIdAndDateRange,
    loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRange,
    loadUtilitiesDetailByDateRange,
    loadUtilitiesDetailYearlyByDateRange,
    (state): UtilityState => ({ ...state, utilitiesDetail: undefined })
  ),
  on(
    loadUtilitySummaries,
    loadUtilitySummariesByFacilityId,
    loadUtilitySummariesByFacilityGroupId,
    (state): UtilityState => ({ ...state, utilitySummaries: undefined })
  ),
  on(loadUtilitySaving, (state): UtilityState => ({ ...state, error: undefined, utilitiesSaving: undefined })),
  on(
    loadUtilitySummariesSuccess,
    loadUtilitySummariesByFacilityIdSuccess,
    loadUtilitySummariesByFacilityGroupIdSuccess,
    (state, { utilitySummaries }): UtilityState => ({ ...state, utilitySummaries })
  ),
  on(
    loadUtilitiesDetailByFacilityIdAndDateRangeSuccess,
    loadUtilitiesDetailYearlyByFacilityIdAndDateRangeSuccess,
    loadUtilitiesDetailByFacilityGroupIdAndDateRangeSuccess,
    loadUtilitiesDetailYearlyByFacilityGroupIdAndDateRangeSuccess,
    loadUtilitiesDetailByDateRangeSuccess,
    loadUtilitiesDetailYearlyByDateRangeSuccess,
    (state, { utilitiesDetail }): UtilityState => ({ ...state, utilitiesDetail })
  ),
  on(loadUtilitySavingSuccess, (state, { utilitiesSaving }): UtilityState => ({ ...state, utilitiesSaving })),
  on(loadUtilitySavingError, (state, { error }): UtilityState => ({ ...state, error, utilitiesSaving: undefined }))
);
