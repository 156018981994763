import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { EditUserRequest, User, UserDetails } from 'brain-data/model/users.model';
import { Observable } from 'rxjs';
import { Role } from 'brain-data/model/role.model';

export const USERS_ADMIN_URL = `${environment.apiBaseAdminUrl}/users`;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(USERS_ADMIN_URL);
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(`${USERS_ADMIN_URL}/${userId}`);
  }

  createUser(email: string): Observable<User> {
    const body = { email: email };

    return this.http.post<User>(`${USERS_ADMIN_URL}`, body);
  }

  editUser(userId: string, editUserRequest: EditUserRequest): Observable<User> {
    return this.http.put<User>(`${USERS_ADMIN_URL}/${userId}`, editUserRequest);
  }

  archiveUser(userId: string): Observable<User> {
    return this.http.put<User>(`${USERS_ADMIN_URL}/${userId}/archive`, null);
  }

  getUserRoles(userId: string): Observable<Role[]> {
    return this.http.get<Role[]>(`${USERS_ADMIN_URL}/${userId}/roles`);
  }

  assignUserRoles(userId: string, roleIds: string[], roleNames: string[]): Observable<void> {
    const body = { rolesId: roleIds, rolesName: roleNames };

    return this.http.post<void>(`${USERS_ADMIN_URL}/${userId}/roles`, body);
  }

  getUserDetails(userId: string): Observable<UserDetails> {
    return this.http.get<UserDetails>(`${USERS_ADMIN_URL}/${userId}/details`);
  }

  assignUserGroupPermissions(userId: string, groupIds: number[]): Observable<void> {
    return this.http.post<void>(`${USERS_ADMIN_URL}/${userId}/assign/group-permissions`, groupIds);
  }
}
