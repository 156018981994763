import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilityBillSummary } from 'brain-data/model/utility-bill-summary.model';
import { Observable } from 'rxjs';
import { DateService } from 'shared/services/utils/date/date.service';
import { UtilityBillUploadEvent } from '../../model/utility-bill-upload-event';

export const UTILITIES_BILLS_URL = `${environment.apiBaseAdminUrl}/utilityBills`;

@Injectable({
  providedIn: 'root',
})
export class UtilityBillsService {
  constructor(private http: HttpClient, private dateService: DateService) {}

  getUtilityBillsUploadEvents(billingYear: string, utilityTypes: string[]) {
    const params = new HttpParams({ fromObject: { billingYear, utilityTypes } });
    return this.http.get<UtilityBillUploadEvent[]>(`${UTILITIES_BILLS_URL}/upload/events`, { params });
  }

  uploadUtilityBill(month: string, year: string, utilityType: string, file?: File): Observable<UtilityBillSummary> {
    const formData: FormData = new FormData();
    formData.append('file', file || new Blob(), file?.name);
    formData.append('utilityType', utilityType);
    formData.append('billingDate', this.dateService.getDateFromMonthAndYear(month, year).toISOString());

    return this.http.post<UtilityBillSummary>(`${UTILITIES_BILLS_URL}/upload`, formData);
  }

  downloadUtilityBillFile(fileName: string, utilityType: string): Observable<Blob> {
    const params = new HttpParams()
      .append('fileName', fileName)
      .append('utilityType', utilityType);
    return this.http.get(
      `${UTILITIES_BILLS_URL}:download`,
      {
        headers: {}, responseType: 'blob', params,
      });
  }
}
