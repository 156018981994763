import { Injectable } from '@angular/core';
import { Consumption, DatedValue, Utility, UtilityDetail, UtilitySummary } from 'brain-data/model/utility.model';
import { UTILITY_ICON, UTILITY_NAME, UTILITY_UNIT } from 'constants/utility.constant';
import { NumberService } from 'shared/services/utils/number/number.service';

@Injectable({
  providedIn: 'root',
})
export class UtilityTransformService {
  constructor(private numberService: NumberService) {
  }

  // FILTER

  filterUtilitiesDetailByDateRange = (utilityDetails: Record<string, {
    usage: DatedValue[];
    spend: DatedValue[];
  }>): UtilityDetail[] => Object.entries(utilityDetails).map(([key, value]) => ({
    [key]: {
      usage: value.usage,
      spend: value.spend,
    },
  }));

  filterUtilitiesToYearly = (utilitiesParam: Utility[]): Utility[] => {
    const utilities: Utility[] = [];

    for (const item of utilitiesParam) {
      const spendData = item?.consumption?.spend;
      const usageData = item?.consumption?.usage;

      const yearlySpendTotal: Record<string, number> = {};
      const yearlyUsageTotal: Record<string, number> = {};

      if (spendData) {
        for (const spend of spendData) {
          const year = spend.date?.split('-')[0];
          const value = spend.value;

          if (year !== undefined) {
            if (year in yearlySpendTotal) {
              yearlySpendTotal[year] += value ?? 0;
            } else {
              yearlySpendTotal[year] = value ?? 0;
            }
          }
        }
      }

      if (usageData) {
        for (const usage of usageData) {
          const year = usage.date?.split('-')[0];
          const value = usage.value;

          if (year !== undefined) {
            if (year in yearlyUsageTotal) {
              yearlyUsageTotal[year] += value ?? 0;
            } else {
              yearlyUsageTotal[year] = value ?? 0;
            }
          }
        }
      }

      const yearlySpendItems: DatedValue[] = [];
      const yearlyUsageItems: DatedValue[] = [];

      const sortedSpendYears = Object.keys(yearlySpendTotal).slice().sort();
      const sortedUsageYears = Object.keys(yearlyUsageTotal).slice().sort();

      for (const year of sortedSpendYears) {
        const spendValue = yearlySpendTotal[year];
        const spendItem: DatedValue = {
          value: spendValue,
          date: year,
        };
        yearlySpendItems.push(spendItem);
      }

      for (const year of sortedUsageYears) {
        const usageValue = yearlyUsageTotal[year];
        const usageItem: DatedValue = {
          value: usageValue,
          date: year,
        };
        yearlyUsageItems.push(usageItem);
      }

      const consumption: Consumption = {
        unit: '',
        value: 0,
        spend: yearlySpendItems,
        usage: yearlyUsageItems,
      };

      const yearlyUtility: Utility = {
        name: item.name,
        icon: item.icon,
        consumption,
      };

      utilities.push(yearlyUtility);
    }

    return utilities;
  };

  // CONVERTS

  convertUtilitySummariesToUtilities = (summary: UtilitySummary): Utility[] => {
    const utilities: Utility[] = [];

    if (summary[UTILITY_NAME.electricity] !== undefined && summary[UTILITY_NAME.electricity] !== 0) {
      utilities.push({
        name: UTILITY_NAME.electricity.charAt(0).toUpperCase() + UTILITY_NAME.electricity.slice(1),
        icon: UTILITY_ICON.electricity,
        consumption: {
          value: summary[UTILITY_NAME.electricity],
          unit: UTILITY_UNIT.kwh,
          spend: [],
          usage: [],
        },
      });
    }

    if (summary[UTILITY_NAME.water] !== undefined && summary[UTILITY_NAME.water] !== 0) {
      utilities.push({
        name: UTILITY_NAME.water.charAt(0).toUpperCase() + UTILITY_NAME.water.slice(1),
        icon: UTILITY_ICON.water,
        consumption: {
          value: summary[UTILITY_NAME.water],
          unit: UTILITY_UNIT.gallons,
          spend: [],
          usage: [],
        },
      });
    }

    if (summary[UTILITY_NAME.gas] !== undefined && summary[UTILITY_NAME.gas] !== 0) {
      utilities.push({
        name: UTILITY_NAME.gas.charAt(0).toUpperCase() + UTILITY_NAME.gas.slice(1),
        icon: UTILITY_ICON.gas,
        consumption: {
          value: summary[UTILITY_NAME.gas],
          unit: UTILITY_UNIT.mmbtu,
          spend: [],
          usage: [],
        },
      });
    }

    if (summary[UTILITY_NAME.solar] !== undefined && summary[UTILITY_NAME.solar] !== 0) {
      utilities.push({
        name: UTILITY_NAME.solar.charAt(0).toUpperCase() + UTILITY_NAME.solar.slice(1),
        icon: UTILITY_ICON.solar,
        consumption: {
          value: summary[UTILITY_NAME.solar],
          unit: UTILITY_UNIT.kwh,
          spend: [],
          usage: [],
        },
      });
    }

    return utilities;
  };

  convertUtilityDetailsToUtilities = (utilityDetails: UtilityDetail[]): Utility[] => {
    const utilities: Utility[] = [];
    for (const utilityDetail of utilityDetails) {
      for (const key in utilityDetail) {
        if (Object.prototype.hasOwnProperty.call(utilityDetail, key)) {
          const utilityData = utilityDetail[key];
          const consumption: Consumption = {
            unit: this.getUtilityUnit(key),
            value: undefined,
            spend: [],
            usage: [],
          };

          for (const spendData of utilityData.spend) {
            const spend: DatedValue = {
              value: this.numberService.formatNumberWithTwoDecimalPlaces(spendData.value),
              date: spendData.date,
            };
            consumption.spend.push(spend);
          }

          for (const usageData of utilityData.usage) {
            const usage: DatedValue = {
              value: this.numberService.formatNumberWithTwoDecimalPlaces(usageData.value),
              date: usageData.date,
            };
            consumption.usage.push(usage);
          }

          if (consumption.spend.length > 0 || consumption.usage.length > 0) {
            const utility: Utility = {
              name: key.charAt(0).toUpperCase() + key.slice(1),
              icon: this.getUtilityIcon(key.charAt(0).toUpperCase() + key.slice(1)),
              consumption,
            };
            utilities.push(utility);
          }
        }
      }
    }
    return utilities;
  };

  // UTILS

  getUtilityIcon = (utilityName: string): string|undefined => {
    switch (utilityName.toLowerCase()) {
      case UTILITY_NAME.electricity:
        return UTILITY_ICON.electricity;
      case UTILITY_NAME.water:
        return UTILITY_ICON.water;
      case UTILITY_NAME.gas:
        return UTILITY_ICON.gas;
      case UTILITY_NAME.solar:
        return UTILITY_ICON.solar;
      default:
        return undefined;
    }
  };

  getUtilityUnit = (utilityName: string): string|undefined => {
    switch (utilityName.toLowerCase()) {
      case UTILITY_NAME.electricity:
        return UTILITY_UNIT.kwh;
      case UTILITY_NAME.water:
        return UTILITY_UNIT.gallons;
      case UTILITY_NAME.gas:
        return UTILITY_UNIT.mmbtu;
      case UTILITY_NAME.solar:
        return UTILITY_UNIT.kwh;
      default:
        return undefined;
    }
  };
}
