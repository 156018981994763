import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NumberService {
  constructor() {}

  formatNumberWithTwoDecimalPlaces(value?: number): number {
    return value !== undefined ? Number(value.toFixed(2)) : 0;
  }
}
