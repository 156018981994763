import { createAction, props } from '@ngrx/store';
import { UpdateUtilityContractRequest } from 'brain-data/message/update-utility-contract-request.model';
import { UtilityContract } from 'brain-data/model/utility-contract.model';

export const loadTotalUtilityContracts = createAction('[Utility contracts] load');
export const loadTotalUtilityContractsSuccess = createAction('[Utility contracts] load success', props<{ totalUtilityContracts: number }>());
export const loadTotalUtilityContractsError = createAction('[Utility contracts] load error', props<{ error: Error }>());

export const loadUtilityContractsWithFacilityUnassigned = createAction('[Utility contracts with facility unassigned] load');
export const loadUtilityContractsWithFacilityUnassignedSuccess = createAction(
  '[Utility contracts with facility unassigned] load success',
  props<{ utilityContracts: UtilityContract[] }>(),
);
export const loadUtilityContractsWithFacilityUnassignedError = createAction(
  '[Utility contracts with facility unassigned] load error',
  props<{ error: Error }>(),
);

export const updateAndAssignFacilityToUtilityContract = createAction(
  '[Utility contracts with facility] update',
  props<{ utilityContractNumber: string; facilityId: number }>(),
);
export const updateAndAssignFacilityToUtilityContractSuccess = createAction('[Utility contracts with facility] update success');
export const updateAndAssignFacilityToUtilityContractError = createAction('[Utility contracts with facility] update error', props<{ error: Error }>());

export const updateAndUnAssignFacilityToUtilityContracts = createAction(
  '[Utility contracts with facility] unassigned',
  props<{ utilityContracts: UtilityContract[] }>(),
);
export const updateAndUnAssignFacilityToUtilityContractsSuccess = createAction('[Utility contracts with facility] update unassigned success');
export const updateAndUnAssignFacilityToUtilityContractsError = createAction(
  '[Utility contracts with facility] update unassigned error',
  props<{ error: Error }>(),
);

export const updateUtilityContracts = createAction(
  '[Utility contracts] update',
  props<{ facilityId: number; updateUtilityContractRequest: UpdateUtilityContractRequest[] }>(),
);
export const updateUtilityContractsSuccess = createAction('[Utility contracts] update success');
export const updateUtilityContractsError = createAction('[Utility contracts] update error', props<{ error: Error }>());
